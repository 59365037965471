import { useTranslation } from "react-i18next";
import EditCompanyDocsSection from "./EditCompanyDocsSection";

const EditCompanyMarketingDocs = () => {
    const { t } = useTranslation("wizard");

    return (
        <EditCompanyDocsSection
            documentType="marketing"
            title={t("uploadMarketing")}
        />
    );
};

export default EditCompanyMarketingDocs;
