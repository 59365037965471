import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCaseGetCase } from "../../../../utils/api/hooks";
import LoadingSpinner from "../../common/components/LoadingSpinner";

type RedirectToEnquiryProps = {
    createEnquiryRoute: (id: number) => string;
};

export const RedirectToEnquiry = (props: RedirectToEnquiryProps) => {
    const { createEnquiryRoute } = props;

    const { caseId } = useParams();

    const navigate = useNavigate();

    const { data: theCase, isLoading: loadingCase } = useCaseGetCase(caseId!);

    useEffect(() => {
        if (!loadingCase && theCase) {
            const enquiryId = theCase.enquiryId;
            navigate(createEnquiryRoute(enquiryId), { replace: true });
        }
    });

    return <LoadingSpinner />;
};
