export const translations = {
    de: {
        loadingFailed: "Fehler beim Laden Ihrer Benachrichtigungen.",
        noUnreads: "Keine ungelesenen Benachrichtigungen",
        panelTitle: "Neueste Benachrichtigungen",
        sectionTitle: "Nachrichten und Mitteilungen",
        seeAll: "Alle ansehen",
    },
    en: {
        loadingFailed: "There has been an error loading your notifications.",
        noUnreads: "You are up to date and have no unread notifications",
        panelTitle: "Latest Notifications",
        sectionTitle: "Messages and Notifications",
        seeAll: "See All",
    },
};
