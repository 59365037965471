import { OfferOnlyType } from "../../../../types";

export const calculateDeliveryDate = ({
    availability,
    timeline,
}: Pick<OfferOnlyType, "availability" | "timeline">) => {
    const deliveryDate = new Date(availability);
    deliveryDate.setDate(deliveryDate.getDate() + Number(timeline));

    return deliveryDate;
};
