import { useTranslation } from "react-i18next";
import EditCompanyDocsSection from "./EditCompanyDocsSection";

const EditCompanyOfferDocs = () => {
    const { t } = useTranslation("wizard");

    return (
        <EditCompanyDocsSection
            documentType="offer"
            title={t('uploadOfferDocs')}
        />
    );
};

export default EditCompanyOfferDocs;
