import { Heading, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/layout";
import { Button, Select, useBreakpointValue } from "@chakra-ui/react";
import { clamp } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRole, useToast } from "../../../../../../common/hooks";
import { OfferType, PropertyType } from "../../../../../../types";
import {
    useEnquiry,
    useEnquiryGetDormant,
    useOfferGetByEnquiryId,
} from "../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../../../common/hooks/useSmartTranslation";
import { Enquiry, Offer, Property } from "../../../../domain";
import { OfferHistoryTable } from "../../../../domain/offer/OfferHistoryTable";
import useRejectMultipleOffers from "../../../../domain/offer/useRejectMultipleOffers";
import { Section } from "../../../../layout";
import { AcceptedOfferTable } from "./AcceptedOfferTable";
import { EnrichedOfferTable } from "./EnrichedOfferTable";

const EnquiryDetails = () => {
    const t = useSmartTranslation();

    const { createErrorToast } = useToast();

    const { enquiryId } = useParams();

    const { roleLoading, userIsClient } = useRole();

    const {
        enquiry,
        refresh: refreshEnquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const properties = enquiry?.properties || [];

    const hasMultipleProperties = properties.length > 1;

    const {
        data: dormantEnquiries,
        isLoading: loadingDormancy,
        refresh: refreshDormancy,
    } = useEnquiryGetDormant();

    const device = useBreakpointValue<"tablet" | "desktop">(
        { base: "tablet", "2xl": "desktop" },
        { ssr: false }
    );

    // offers

    const {
        data: offers,
        isLoading: offersLoading,
        refresh: refreshOffers,
    } = useOfferGetByEnquiryId(Number(enquiryId));

    const [checkedOffers, setCheckedOffers] = useState<OfferType[]>([]);

    const onError = (error: Error) => createErrorToast(error.message);

    const onUpdateEnquiry = () => {
        refreshEnquiry();
        refreshDormancy();
    };

    const { rejectOffers, confirmationModal: offerRejectionConfirmationModal } =
        useRejectMultipleOffers({
            onError,
            refresh: refreshOffers,
            offers: checkedOffers,
        });

    const offerActivities = createOfferActivities(rejectOffers);

    const [activeOfferActivity, setActiveOfferActivity] =
        useState<OfferActivityType>(offerActivities["REJECT"]);

    const [selectedProperty, setSelectedProperty] = useState<
        PropertyType | undefined
    >(undefined);

    const selectedPropertyIndex = properties.findIndex(
        (i) => i.id === selectedProperty?.id
    );

    useEffect(() => {
        if (hasMultipleProperties) return;
        if (properties.length === 0) return;

        setSelectedProperty(properties[0]);
    }, [properties, hasMultipleProperties]);

    if (
        roleLoading ||
        !enquiry ||
        enquiryLoading ||
        !dormantEnquiries ||
        loadingDormancy ||
        !offers ||
        offersLoading
    )
        return <LoadingSpinner />;

    // enquiry

    const dormantForUser = dormantEnquiries
        .map((enq) => enq.id)
        .includes(enquiry.id);

    const title = (
        <Enquiry.Title
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            userIsClient={userIsClient}
            onUpdate={onUpdateEnquiry}
            onError={onError}
        />
    );

    const requestedValuationSection = (
        <Section
            collapsable
            title={t("domain.enquiry.requestedValuationTitle")}
            content={<Enquiry.Requirements enquiry={enquiry} />}
        />
    );

    // offers

    const activeOffers = offers.filter((o) => o.status === "PENDING");

    const toggleOfferChecked = (offer: OfferType, check: boolean) => {
        if (!check) {
            const idx = checkedOffers.indexOf(offer);
            if (idx !== -1) {
                const newChecked = [...checkedOffers];
                newChecked.splice(idx, 1);
                setCheckedOffers(newChecked);
            }
        } else {
            if (checkedOffers.includes(offer)) return;
            setCheckedOffers([...checkedOffers, offer]);
        }
    };

    const offersFooter = checkedOffers.length && (
        <HStack>
            <Text>
                {t("clientEnquiryDetails.activeOffers.footer.selected", {
                    count: checkedOffers.length,
                })}
            </Text>
            <Select
                width={"140px"}
                value={activeOfferActivity.key}
                onChange={(e) =>
                    setActiveOfferActivity(
                        createOfferActivities(rejectOffers)[e.target.value]
                    )
                }
            >
                {createOptions(offerActivities, t)}
            </Select>
            <Button onClick={() => activeOfferActivity.execute(checkedOffers)}>
                {t("clientEnquiryDetails.activeOffers.footer.apply")}
            </Button>
            {offerRejectionConfirmationModal}
        </HStack>
    );

    const hasAcceptedOffers = offers.some(
        (offer) => offer.status === "ACCEPTED"
    );

    const offersSectionTitle = hasAcceptedOffers
        ? t("clientEnquiryDetails.acceptedOffer.title")
        : t("clientEnquiryDetails.activeOffers.title", {
              count: activeOffers.length,
          });

    const offersSectionContent = offers.length ? (
        hasAcceptedOffers ? (
            <AcceptedOfferTable offers={offers} />
        ) : (
            <EnrichedOfferTable
                offers={activeOffers}
                checkedOffers={checkedOffers}
                toggleOfferChecked={toggleOfferChecked}
                footerContent={offersFooter}
            />
        )
    ) : (
        <Offer.NoOffersView />
    );

    const offersSection = (
        <Section
            collapsable
            title={offersSectionTitle}
            content={offersSectionContent}
        />
    );

    // offer history

    const historicOffers = offers.filter((o) =>
        ["REJECTED", "WITHDRAWN"].includes(o.status)
    );

    const historyContent = <OfferHistoryTable offers={historicOffers} />;

    const offerHistorySection = (
        <Section
            collapsable
            title={t("clientEnquiryDetails.offerHistory.title")}
            content={historyContent}
        />
    );

    // properties

    const propertyPortfolioSection = hasMultipleProperties && (
        <Section
            collapsable
            title={t("domain.property.propertyPortfolio.title", {
                count: properties.length,
            })}
            content={
                <>
                    <Property.PortfolioTable
                        properties={properties}
                        selectedProperty={selectedProperty}
                        onRowClick={(property) => setSelectedProperty(property)}
                    />

                    <Property.PropertyDrawer
                        properties={properties}
                        selectedProperty={selectedProperty}
                        onClose={() => setSelectedProperty(undefined)}
                        onNextProperty={() => {
                            const nextIndex = clamp(
                                selectedPropertyIndex + 1,
                                0,
                                properties.length - 1
                            );

                            setSelectedProperty(properties[nextIndex]);
                        }}
                        onPreviousProperty={() => {
                            const nextIndex = clamp(
                                selectedPropertyIndex - 1,
                                0,
                                properties.length - 1
                            );

                            setSelectedProperty(properties[nextIndex]);
                        }}
                    />
                </>
            }
        />
    );

    const propertyDetails = !hasMultipleProperties && selectedProperty && (
        <Section
            collapsable
            title={t("domain.property.propertyDetails.title")}
            content={
                <>
                    <Property.PropertiesMap
                        properties={enquiry.properties}
                        selectedProperty={selectedProperty}
                    />

                    <Heading
                        size="md"
                        my=".5em"
                    >
                        {`${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`}
                    </Heading>
                    <Property.PropertyDetails property={selectedProperty} />
                </>
            }
        />
    );

    // render

    return (
        <VStack
            w={"100%"}
            padding=".5em"
            spacing={4}
        >
            {title}
            <SimpleGrid
                width={"100%"}
                columns={{ base: 1, "2xl": 2 }}
                spacing={2}
                gridTemplateColumns={device === "tablet" ? "100%" : "40% 60%"}
            >
                <VStack>
                    {requestedValuationSection}
                    {propertyPortfolioSection}
                    {propertyDetails}
                </VStack>
                <VStack>
                    {offersSection}
                    {offerHistorySection}
                </VStack>
            </SimpleGrid>
        </VStack>
    );
};

type OfferActivityType = {
    key: string;
    label: (t: TranslationFunction) => string;
    execute: ((offers: OfferType[]) => void) | (() => void);
};

const createOfferActivities = (
    rejectOffers: () => void
): Record<string, OfferActivityType> => {
    return {
        REJECT: {
            key: "REJECT",
            label: (t) => t("domain.offer.ACTIVITIES.reject.NAME"),
            execute: () => rejectOffers(),
        },
    };
};

const createOptions = (
    options: Record<string, OfferActivityType>,
    t: TranslationFunction
) => {
    return Object.entries(options).map(([key, option]) => (
        <option
            key={key}
            value={key}
        >
            {option.label(t)}
        </option>
    ));
};

export default EnquiryDetails;
