import { Grid, GridItem, VStack } from "@chakra-ui/layout";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Enquiry, Property } from "../../../../domain";
import { testCase } from "../../../../domain/case/testdata";
import { pendingOffer } from "../../../../domain/offer/testdata";
import { testValuerUser } from "../../../../domain/user/testdata";
import { Section } from "../../../../layout";
import { OfferDetails } from "./OfferDetails";
import { OfferTitle } from "./OfferTitle";
import { ValuerCompanyDetails } from "./ValuerCompanyDetails";
import { ValuerCompetences } from "./ValuerCompetences";
import { ValuerDetails } from "./ValuerDetails";
import { ValuerQualifications } from "./ValuerQualifications";

const OfferDetailsScreen = () => {
    const offer = pendingOffer;
    const theCase = testCase;
    const valuer = testValuerUser;

    const t = useSmartTranslation();

    const title = (
        <OfferTitle
            offer={offer}
            case={theCase}
        />
    );

    const offerDetails = (
        <Section
            title={t("screens.platform.client.offerDetails.offerDetailsTitle")}
            content={<OfferDetails offer={offer} />}
        />
    );
    const requestedValuation = (
        <Section
            title={t("domain.enquiry.requestedValuationTitle")}
            content={<Enquiry.Requirements enquiry={theCase.enquiry} />}
        />
    );
    const propertyPortfolio = (
        <Section
            title={t("domain.property.propertyPortfolio.title")}
            content={
                <Property.PropertyPortfolioSummary
                    properties={theCase.enquiry.properties}
                />
            }
        />
    );

    const valuerDetails = (
        <Section
            title={t("screens.platform.client.offerDetails.valuerDetailsTitle")}
            content={<ValuerDetails valuer={valuer} />}
        />
    );
    const valuerQualifications = (
        <Section
            title={t("screens.platform.client.offerDetails.valuerQualificationsTitle")}
            content={<ValuerQualifications valuer={valuer} />}
        />
    );
    const valuerCompetences = (
        <Section
            title={t("screens.platform.client.offerDetails.valuerCompetencesTitle")}
            content={<ValuerCompetences valuer={valuer} />}
        />
    );
    const valuerCompanyDetails = (
        <Section
            title={t("screens.platform.client.offerDetails.valuerCompanyDetailsTitle")}
            content={<ValuerCompanyDetails valuer={valuer} />}
        />
    );

    return (
        <VStack
            w={"100%"}
            padding=".5em"
            spacing={4}
        >
            {title}
            <Grid
                width={"100%"}
                gap={2}
                gridTemplateColumns={{
                    sm: "30% 70%",
                    "2xl": "55% 45%",
                }}
            >
                <GridItem>{offerDetails}</GridItem>
                <GridItem>{valuerDetails}</GridItem>
                <GridItem>{requestedValuation}</GridItem>
                <GridItem>{valuerQualifications}</GridItem>
                <GridItem>{propertyPortfolio}</GridItem>
                <GridItem>{valuerCompetences}</GridItem>
                <GridItem colStart={2}>{valuerCompanyDetails}</GridItem>
            </Grid>
        </VStack>
    );
};

export default OfferDetailsScreen;
