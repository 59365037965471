import { EntityType } from "../../../../types";

// as unknown because EntityType.valuerYearsOfExperience is specced incorrectly atm.
export const testValuerEntity = {
    id: 2,
    accountType: "VALUER",
    name: "Valuer Company Ltd.",
    valuerYearsOfExperience: "3",
} as unknown as EntityType;

export const testClientEntity = {
    id: 1,
    accountType: "CLIENT",
    name: "Client Company",
} as EntityType;
