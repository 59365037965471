import { offerGetMyOfferByEnquiryId } from "../endpoints";
import useQueryHook from "./useQueryHook";

const OFFER_MY_OFFERS_BY_ENQUIRY_ID = "OFFER_MY_OFFERS_BY_ENQUIRY_ID";

const useOfferGetMyOfferByEnquiryId = (enquiryId?: number | string) =>
    useQueryHook({
        uniqueName: OFFER_MY_OFFERS_BY_ENQUIRY_ID,
        queryFunction: () => offerGetMyOfferByEnquiryId(enquiryId!),
        dependancies: [enquiryId],
        requiredParams: [enquiryId !== undefined],
    });

export default useOfferGetMyOfferByEnquiryId;
