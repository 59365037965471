import { translations } from "./i18n";
import PortfolioTable from "./PortfolioTable";
import { PropertiesMap } from "./PropertiesMap";
import { PropertyDetails } from "./PropertyDetails";
import { PropertyDrawer } from "./PropertyDrawer";
import { PropertyPortfolioSummary } from "./PropertyPortfolioSummary";

export const Property = {
    PortfolioTable,
    PropertiesMap,
    PropertyDetails,
    PropertyDrawer,
    PropertyPortfolioSummary,
    translations,
};
