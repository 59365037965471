import { OfferOnlyType } from "../../../../../../types";

type OfferDetailsProps = {
    offer: OfferOnlyType;
    // buttons?: React.ReactNode | React.ReactNode[];
};

const OfferDetails = (props: OfferDetailsProps) => {
    return <div>OfferDetails</div>;
};

export { OfferDetails };
