import { NotAllowedIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Divider,
    Flex,
    Icon,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { CloseCircle } from "iconsax-react";
import { useState } from "react";
import { AiOutlineCheckCircle, AiOutlineWarning } from "react-icons/ai";
import delimitNumber from "../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../common/functions/displayTime";
import { OfferOnlyType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { calculateDeliveryDate } from "./offerFunctions";
import { OfferStatus as OfferStatusType } from "./offerStatus";

type OfferDetailsProps = {
    offer: OfferOnlyType;
    buttons?: React.ReactNode | React.ReactNode[];
};

export const OfferDetails = (props: OfferDetailsProps) => {
    const {
        offer: {
            updatedAt,
            piCoverValue,
            piCoverPercentage,
            timeline,
            availability,
            price,
            comments: message,
        },
        offer,
        buttons,
    } = props;

    const t = useSmartTranslation();

    const earliestAvailability = displayDateWithCurrentLocale(availability);
    const delivery = displayDateWithCurrentLocale(calculateDeliveryDate(offer));

    return (
        <VStack>
            <OfferStatus offer={offer} />
            <OfferProperty
                label={t("domain.offer.piCoverAbsolute")}
                value={`€ ${delimitNumber(piCoverValue!)}`}
            />
            <OfferProperty
                label={t("domain.offer.piCoverPercentage")}
                value={`${piCoverPercentage!}%`}
            />
            <OfferProperty
                label={t("domain.offer.timeline")}
                value={`${timeline} ${t("common.units.days")}`}
            />
            <OfferProperty
                label={t("domain.offer.availability")}
                value={earliestAvailability}
            />
            <OfferProperty
                label={t("domain.offer.deliveryDate")}
                value={delivery}
                color={"green.500"}
            />
            <Divider
                orientation={"horizontal"}
                variant={"dashed"}
                background={"gray.500"}
            />
            <OfferProperty
                label={t("domain.offer.price")}
                value={`€ ${delimitNumber(price)}`}
                color={"green.500"}
                valueSize={"1.5em"}
            />
            <SubmissionTimestamp submissionDate={updatedAt} />
            <OfferMessage message={message} />
            <Flex
                mt={"1rem"}
                w={"100%"}
                gap={".5rem"}
            >
                {buttons}
            </Flex>
        </VStack>
    );
};

const MAX_CHARS_TO_DISPLAY = 180;

type OfferMessageProps = {
    message?: string;
};

const OfferMessage = (props: OfferMessageProps) => {
    const { message } = props;

    const t = useSmartTranslation();

    const [showAll, setShowAll] = useState<boolean>(false);

    let visibleText;
    let showMoreOrLess;

    if (message) {
        const textExceedsCharLimit = message.length > MAX_CHARS_TO_DISPLAY;

        if (showAll) visibleText = message;
        else
            visibleText = textExceedsCharLimit
                ? message.substring(0, MAX_CHARS_TO_DISPLAY) + "..."
                : message;

        if (textExceedsCharLimit) {
            const label = t(showAll ? "common.showLess" : "common.showMore");

            showMoreOrLess = (
                <Button
                    variant={"link"}
                    color={"blue.400"}
                    onClick={() => setShowAll(!showAll)}
                >
                    {label}
                </Button>
            );
        }
    } else visibleText = t("domain.offer.noCommentsPlaceholder");

    return (
        <VStack
            w={"100%"}
            mt={"2em"}
            align={"start"}
        >
            <Text color={"gray.600"}>{t("domain.offer.comments")}</Text>
            <Text align={"start"}>{visibleText}</Text>
            {showMoreOrLess}
        </VStack>
    );
};

type SubmissionTimestampProps = {
    submissionDate: string;
};

const SubmissionTimestamp = (props: SubmissionTimestampProps) => {
    const { submissionDate } = props;

    const date = displayDateWithCurrentLocale(submissionDate);
    const time = displayTimeInCurrentLocale(new Date(submissionDate));

    const t = useSmartTranslation();

    return (
        <Flex w={"100%"}>
            <Spacer />
            <Text color={"gray.600"}>
                {`${t("domain.offer.submittedAt")} ${date}, ${time}`}
            </Text>
        </Flex>
    );
};

type OfferPropertyProps = {
    label?: string;
    value: string | number;
    color?: string;
    valueSize?: string;
};

const OfferProperty = (props: OfferPropertyProps) => {
    const { label, value, color, valueSize } = props;

    return (
        <Flex
            w={"100%"}
            align={"center"}
        >
            <Text color={"gray.600"}>{label}</Text>
            <Spacer />
            <Text
                color={color}
                fontSize={valueSize}
                fontWeight={"bold"}
            >
                {value}
            </Text>
        </Flex>
    );
};

type OfferStatusProps = {
    offer: Pick<OfferOnlyType, "caseId" | "status" | "updatedAt">;
};

type OfferStatusConfig = {
    color: string;
    icon: React.ComponentType;
    description: string;
};

const OfferStatus = (props: OfferStatusProps) => {
    const {
        offer: { caseId, status, updatedAt },
    } = props;

    const t = useSmartTranslation();

    const date = `(${displayDateWithCurrentLocale(
        updatedAt
    )}, ${displayTimeInCurrentLocale(new Date(updatedAt))})`;

    const statusConfig: Record<OfferStatusType, OfferStatusConfig> = {
        ACCEPTED: {
            color: "green",
            icon: AiOutlineCheckCircle,
            // TODO: redirect to the case details ?
            description: t("domain.offer.statusContent.ACCEPTED", {
                caseId,
            }),
        },
        PENDING: {
            color: "orange",
            icon: AiOutlineWarning,
            description: t("domain.offer.statusContent.PENDING"),
        },
        REJECTED: {
            color: "red",
            icon: CloseCircle,
            description: t("domain.offer.statusContent.REJECTED"),
        },
        WITHDRAWN: {
            color: "red",
            icon: NotAllowedIcon,
            description: t("domain.offer.statusContent.WITHDRAWN"),
        },
    };

    const config = statusConfig[status];

    return (
        <Box
            borderRadius={8}
            bg={`${config.color}.50`}
            w={"100%"}
            p={4}
            mb={2}
        >
            <Flex
                align={"center"}
                gap={2}
                mb={1}
            >
                <Icon
                    as={config.icon}
                    w={6}
                    h={6}
                    color={`${config.color}.500`}
                />
                <Text fontWeight={700}>
                    {t(`domain.offer.status.${status}`)}
                </Text>
                <Text>{date}</Text>
            </Flex>
            <Text
                fontSize={"0.8em"}
                color={"gray.500"}
            >
                {config.description}
            </Text>
        </Box>
    );
};
