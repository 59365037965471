import { Box, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { JSXElementConstructor, ReactElement } from "react";
import {
    EnquiryType,
    EntityType,
    OfferType,
    UserType,
} from "../../../../../../types";
import { ProfileBookmarkIcon } from "../../../../common/components/display";
import ProfilePicture from "../../../../common/components/display/ProfilePicture";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Email, Fax, Mobile } from "../../../../common/icons/Icons";
import { Enquiry } from "../../../../domain";
import PublicUserProfilePictureWithEntityLogo from "../../../../common/components/PublicUserProfilePictureWithEntityLogo";

type Props = {
    clientData: UserType;
    offer?: OfferType;
    enquiry: EnquiryType;
    dormantForUser: boolean;
    userIsClient: boolean;
    panelValuerList: EntityType[];
    onUpdate: () => any;
    onError: (error: Error) => any;
};

export const ValuerEnquiryTitle = (props: Props) => {
    const {
        clientData,
        offer,
        enquiry,
        dormantForUser,
        userIsClient,
        panelValuerList,
        onUpdate,
        onError,
    } = props;

    const isInValuerPanel = panelValuerList.find(
        (panelValuers) => panelValuers.id === enquiry.fromEntityId
    );

    const titleClient =
        (enquiry.status === "ACCEPTED" && offer?.status === "ACCEPTED") ||
        isInValuerPanel ? (
            <TitleActualClient client={clientData} />
        ) : (
            <ObscuredClient />
        );

    return (
        <Enquiry.Title
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            userIsClient={userIsClient}
            onUpdate={onUpdate}
            onError={onError}
            clientInfo={titleClient}
        />
    );
};

const ObscuredClient = () => {
    const t = useSmartTranslation();

    return (
        <TitleClient
            name={t("domain.client.obscuredClient")}
            position={t(
                "screens.platform.valuer.valuerEnquiryDetails.enquiryTitle.detailsAvailableAfterOfferAcceptance"
            )}
        />
    );
};

type TitleActualClientProps = {
    client: UserType;
};

const TitleActualClient = (props: TitleActualClientProps) => {
    const { client } = props;

    const clientName = `${client.firstName} ${client.lastName}`;
    const clientPosition = `${client.orgPosition}`;
    const clientCompany = `${client.entity.name}`;
    const clientId = client.id;
    const entityId = client.entityId;

    return (
        <TitleClient
            name={clientName}
            position={clientPosition}
            company={clientCompany}
            hasBookmarkIcon={true}
            clientId={clientId}
            entityId={entityId}
        />
    );
};

type TitleClientProps = {
    company?: string;
    hasBookmarkIcon?: boolean;
    position: string;
    name: string;
    entityId?: string | number;
    clientId?: string | number;
};

const TitleClient = (props: TitleClientProps) => {
    const { company, position, name, hasBookmarkIcon, clientId, entityId } =
        props;

    const t = useSmartTranslation();

    return (
        <HStack>
            <Box position={"relative"}>
                {hasBookmarkIcon && <ProfileBookmarkIcon />}

                {clientId && entityId ? (
                    <PublicUserProfilePictureWithEntityLogo
                        entityId={entityId}
                        userId={clientId}
                    />
                ) : (
                    <ProfilePicture />
                )}
            </Box>
            <VStack
                align={"flex-start"}
                spacing="0"
            >
                <Text fontWeight="bold">{name}</Text>
                <Text
                    fontSize={"0.8em"}
                    variant="secondary"
                >
                    {position}
                    {position &&
                        company &&
                        ` ${t("domain.client.at")} ${company}`}
                </Text>
            </VStack>
            <ConnectButton
                ariaLabel="mobile"
                icon={<Mobile />}
            />
            <ConnectButton
                ariaLabel="email"
                icon={<Email />}
            />
            <ConnectButton
                ariaLabel="fax"
                icon={<Fax />}
            />
        </HStack>
    );
};

//helpers
type ConnectButtonType = {
    icon: ReactElement<any, string | JSXElementConstructor<any>>;
    ariaLabel: string;
};
const ConnectButton = (props: ConnectButtonType) => {
    const { ariaLabel, icon } = props;
    return (
        <IconButton
            aria-label={ariaLabel}
            icon={icon}
            color="gray.400"
            bg="gray.50"
            borderRadius={30}
        />
    );
};
