import { UserType } from "../../../../../../types";

type ValuerCompanyDetailsProps = {
    valuer: UserType;
};

const ValuerCompanyDetails = (props: ValuerCompanyDetailsProps) => {
    return <div>ValuerCompanyDetails</div>;
};

export { ValuerCompanyDetails };
