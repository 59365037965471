import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { PropertyType } from "../../../../../../types";
import { NO_DATA } from "../../../NO_DATA";
import { DataTableColumnType } from "../DataTable";
import { Heading, Text, VStack } from "@chakra-ui/react";

type ColumnType = DataTableColumnType<PropertyType>;

const translationNameSpace =
    "common.components.dataTable.columns.propertyColumns";

export const propertyId: ColumnType = {
    title: () => "#",
    render: (p) => <Text>{p.id}</Text>,
};

export const propertyAddress: ColumnType = {
    title: (t) => t(`${translationNameSpace}.address`),
    render: (p) => {
        const { street, city, postalCode } = p;
        return (
            <VStack
                align="left"
                spacing={0}
            >
                <Heading size="xs">{street}</Heading>
                <Text
                    fontSize="14px"
                    variant="secondary"
                >
                    {`${postalCode} ${city}`}
                </Text>
            </VStack>
        );
    },
};

export const propertyType: ColumnType = {
    title: (t) => t(`${translationNameSpace}.propertyType`),
    render: (p, t) => {
        const { propertyTypes } = p;

        const isMixedProperty = propertyTypes.length > 1;

        const propertyType = t(`domain.standards.propertyType.${isMixedProperty ? 'Mixed' : propertyTypes[0]}`);

        return (
            <Text>
                {propertyType}
            </Text>
        );
    },
};

export const area: ColumnType = {
    title: (t) => t(`${translationNameSpace}.area`),
    render: (p) => {
        const { areaOverall, areaGrossInternal } = p;
        return (
            <VStack
                align="left"
                spacing={0}
            >
                <Text>{`${delimitNumber(areaGrossInternal)} m²`}</Text>
                <Text
                    fontSize="14px"
                    variant="secondary"
                >
                    {`${
                        areaOverall !== null
                            ? delimitNumber(areaOverall)
                            : NO_DATA
                    } m²`}
                </Text>
            </VStack>
        );
    },
};

export const estimateValue: ColumnType = {
    title: (t) => t(`${translationNameSpace}.estimateValue`),
    render: (p) => {
        const { estimateValue } = p;
        return (
            <Text
                fontWeight="bold"
                color="cyan.500"
            >
                {`€${delimitNumber(estimateValue)}`}
            </Text>
        );
    },
};

export const propertyColumns = {
    propertyId,
    propertyAddress,
    propertyType,
    area,
    estimateValue,
};
