import { enforceEntityPICover } from "./enforceEntityPICover";
import { translations } from "./i18n";
import { NoOffersView } from "./NoOffersView";
import { offerColumns } from "./OfferColumns";
import { OfferComponent } from "./OfferComponent";
import { calculateDeliveryDate } from "./offerFunctions";

export const Offer = {
    calculateDeliveryDate,
    NoOffersView: NoOffersView,
    TableColumns: offerColumns,
    translations: translations,
    Offer: OfferComponent,
    enforceEntityPICover,
};

export type PICoverParams = {
    piCoverSpecialArrangementsAvailable: boolean;
    defaultPICoverValue: number;
    defaultPICoverPercentage: number;
};
