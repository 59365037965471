import { SimpleGrid, VStack } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../../../../../common/hooks";
import {
    EnquiryType,
    EntityType,
    OfferType,
    UserType,
} from "../../../../../../types";
import {
    useEnquiry,
    useEnquiryGetDormant,
    useMyValuerPanels,
    useOfferCreate,
    useOfferEdit,
    useOfferGetMyOfferByEnquiryId,
    usePublicUser,
} from "../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Offer, PICoverParams, User } from "../../../../domain";
import { EnquiryMatchInfo } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import matchEnquiryWithValuer from "../../../../domain/enquiry/enquiryRequirements/matchEnquiryWithValuer";
import { OfferCreationFormDataType } from "../../../../domain/offer/OfferCreationFormOutput";
import { Section } from "../../../../layout";
import { ValuerEnquiryTitle } from "./EnquiryTitle";
import MultiPropertyLayout from "./MultiPropertyLayout";
import SinglePropertyLayout from "./SinglePropertyLayout";

const translationNS = "screens.platform.valuer.valuerEnquiryDetails";

type EnquiryDetailsProps = {
    user: User;
};

const EnquiryDetails = (props: EnquiryDetailsProps) => {
    const { user } = props;

    const { createErrorToast } = useToast();

    const { enquiryId } = useParams();

    const {
        enquiry,
        refresh: refreshEnquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const {
        data: dormantEnquiries,
        isLoading: loadingDormancy,
        refresh: refreshDormancy,
    } = useEnquiryGetDormant();

    const {
        data: offer,
        isLoading: myOfferLoading,
        refresh: refreshMyOffer,
    } = useOfferGetMyOfferByEnquiryId(enquiryId);

    const {
        data: clientData,
        isLoading: clientDataIsLoading,
        refresh: refreshClientData,
    } = usePublicUser(Number(enquiry?.fromUserId));

    const {
        data: panelValuerList,
        isLoading: isLoadingPanelValuerList,
        refresh: refreshPanelValuerList,
    } = useMyValuerPanels();

    const [matchInfo, setMatchinfo] = useState<EnquiryMatchInfo | undefined>();

    useEffect(() => {
        if (enquiry) {
            const getMatchInfo = async () => {
                const matchInfo = await matchEnquiryWithValuer(
                    enquiry.id,
                    user.id
                );
                setMatchinfo(matchInfo);
            };

            try {
                getMatchInfo();
            } catch (e) {
                console.log(`Problem when retrieving matchinfo: ${e}`);
            }
        }
    }, [enquiry]);

    if (
        enquiryLoading ||
        !dormantEnquiries ||
        loadingDormancy ||
        myOfferLoading ||
        clientDataIsLoading ||
        !clientData ||
        isLoadingPanelValuerList ||
        !panelValuerList
    )
        return <LoadingSpinner />;

    // actually: if there's no enquiry we should show a 404 or so
    if (!enquiry || !matchInfo) return <LoadingSpinner />;

    const onUpdate = () => {
        refreshEnquiry();
        refreshDormancy();
        refreshMyOffer();
        refreshClientData();
        refreshPanelValuerList();
    };
    const onError = (error: Error) => createErrorToast(error.message);

    const dormantForUser = dormantEnquiries
        .map((enq) => enq.id)
        .includes(enquiry.id);

    return (
        <EnquiryDetailsScreen
            clientData={clientData}
            myOffer={offer}
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            matchInfo={matchInfo}
            user={user}
            valuerId={user}
            panelValuerList={panelValuerList}
            onUpdate={onUpdate}
            onError={onError}
        />
    );
};

type EnquiryDetailsScreenProps = {
    clientData: UserType;
    myOffer?: OfferType;
    enquiry: EnquiryType;
    dormantForUser: boolean;
    matchInfo: EnquiryMatchInfo;
    user: UserType;
    valuerId: User;
    panelValuerList: EntityType[];
    onUpdate: () => any;
    onError: (error: Error) => any;
};

const EnquiryDetailsScreen = (props: EnquiryDetailsScreenProps) => {
    const {
        clientData,
        myOffer,
        matchInfo,
        enquiry,
        dormantForUser,
        user,
        user: {
            entity: {
                piCoverSpecialArrangementsAvailable,
                piCoverValue,
                piCoverPercentage,
            },
        },
        panelValuerList,
        onUpdate,
        onError,
    } = props;

    const device = useBreakpointValue<"tablet" | "desktop">(
        { base: "tablet", "2xl": "desktop" },
        { ssr: false }
    );

    const t = useSmartTranslation();

    const hasMultipleProperties = enquiry.properties.length > 1;

    // enquiry
    const title = (
        <ValuerEnquiryTitle
            clientData={clientData}
            offer={myOffer}
            enquiry={enquiry}
            dormantForUser={dormantForUser}
            panelValuerList={panelValuerList}
            userIsClient={false}
            onUpdate={onUpdate}
            onError={onError}
        />
    );

    const { update: createOffer } = useOfferCreate({
        onSuccess: onUpdate,
        onError,
    });

    const { edit: editOffer } = useOfferEdit({ onSuccess: onUpdate, onError });

    const piCover: PICoverParams = {
        defaultPICoverValue: piCoverValue || 0,
        defaultPICoverPercentage: piCoverPercentage || 0,
        piCoverSpecialArrangementsAvailable,
    };

    const createFromDraft = (draft: OfferCreationFormDataType) => {
        const dto = Offer.enforceEntityPICover(
            {
                ...draft,
                fromValuerId: user.id,
                fromEntityId: user.entityId,
                toEntityId: enquiry.fromEntityId,
                enquiryId: enquiry.id,
            },
            piCover
        );

        createOffer(dto);
    };

    let editFromDraft;
    let myOfferEditor;

    if (myOffer) {
        editFromDraft = (draft: OfferCreationFormDataType) => {
            const dto = Offer.enforceEntityPICover(
                {
                    ...draft,
                    offerId: myOffer.id,
                },
                piCover
            );

            editOffer(dto);
        };
        myOfferEditor = (
            <Section
                title={t(`${translationNS}.myOffer.title`)}
                content={
                    <Offer.Offer
                        offer={myOffer}
                        piCover={piCover}
                        createOffer={createFromDraft}
                        editOffer={editFromDraft}
                        onWithdrawOffer={onUpdate}
                        onError={onError}
                    />
                }
            />
        );
    } else {
        myOfferEditor = (
            <Section
                title={t(`${translationNS}.myOffer.title`)}
                content={
                    <Offer.Offer
                        offer={myOffer}
                        piCover={piCover}
                        createOffer={createFromDraft}
                        onError={onError}
                    />
                }
            />
        );
    }

    // render

    return (
        <VStack
            w={"100%"}
            padding=".5em"
            spacing={4}
        >
            {title}
            <SimpleGrid
                width={"100%"}
                columns={{ base: 2, "2xl": 3 }}
                spacing={2}
                gridTemplateColumns={
                    device === "tablet" ? "55% 45%" : "40% 1fr 30%"
                }
            >
                {hasMultipleProperties ? (
                    <MultiPropertyLayout
                        enquiry={enquiry}
                        matchInfo={matchInfo}
                        device={device}
                        myOffer={myOfferEditor}
                    />
                ) : (
                    <SinglePropertyLayout
                        enquiry={enquiry}
                        matchInfo={matchInfo}
                        device={device}
                        myOffer={myOfferEditor}
                    />
                )}
            </SimpleGrid>
        </VStack>
    );
};

export default EnquiryDetails;
