import { Box, Icon, Tooltip } from "@chakra-ui/react";
import { BookmarkCheck } from "../../icons/Icons";

type PropTypes = {
    tooltip?: string;
};

const ProfileBookmarkIcon = (props: PropTypes) => {
    const { tooltip } = props;

    const iconBox = (
        <Box
            position={"absolute"}
            top={"0"}
            right={"0"}
        >
            <Icon
                as={BookmarkCheck}
                boxSize={"20px"}
                verticalAlign={"top"}
            />
        </Box>
    );

    return tooltip ? (
        <Tooltip
            label={tooltip}
        >
            {iconBox}
        </Tooltip>
    ) : (
        iconBox
    );
};

export default ProfileBookmarkIcon;
