export const translations = {
    de: {
        enquiryTitle: {
            detailsAvailableAfterOfferAcceptance:
                "Kundendetails verfügbar nach Angebotsannahme",
        },
        companyOffers: {
            title: "Angebote der Firma: {{nCompanyOffers}}",
        },
        myOffer: {
            title: "Mein Angebot",
        },
    },
    en: {
        enquiryTitle: {
            detailsAvailableAfterOfferAcceptance:
                "Client details available after offer acceptance",
        },
        companyOffers: {
            title: "Company's Offers: {{nCompanyOffers}}",
        },
        myOffer: {
            title: "My Offer",
        },
    },
};
