import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Heading,
    IconButton,
    Spacer,
} from "@chakra-ui/react";
import { PropertyType } from "../../../../types";
import { PropertiesMap } from "./PropertiesMap";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { PropertyDetails } from "./PropertyDetails";
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";

type PropsType = {
    properties: PropertyType[];
    selectedProperty: PropertyType | undefined;
    onClose: () => void;
    onNextProperty: () => void;
    onPreviousProperty: () => void;
};

export const PropertyDrawer = (props: PropsType) => {
    const {
        properties,
        selectedProperty,
        onClose,
        onNextProperty,
        onPreviousProperty,
    } = props;

    const t = useSmartTranslation();

    if (!selectedProperty) return null;

    const selectedPropertyIndex = properties.findIndex(
        (i) => i.id === selectedProperty.id
    );

    return (
        <Drawer
            isOpen
            placement="right"
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerHeader>
                    <Flex>
                        <Heading
                            size="md"
                            color="blue.700"
                        >
                            {t("domain.property.propertyDetails.title")}
                        </Heading>

                        <Spacer />

                        <IconButton
                            isDisabled={selectedPropertyIndex === 0}
                            aria-label={t("common.button.previous")}
                            icon={<ChevronLeftIcon boxSize="8" />}
                            variant="ghost"
                            onClick={() => onPreviousProperty()}
                        />

                        <IconButton
                            isDisabled={
                                selectedPropertyIndex === properties.length - 1
                            }
                            aria-label={t("common.button.next")}
                            icon={<ChevronRightIcon boxSize="8" />}
                            variant="ghost"
                            onClick={() => onNextProperty()}
                        />

                        <IconButton
                            aria-label={t("common.button.close")}
                            icon={<CloseIcon />}
                            variant="ghost"
                            onClick={() => onClose()}
                        />
                    </Flex>
                </DrawerHeader>

                <DrawerBody>
                    <PropertiesMap
                        properties={properties}
                        selectedProperty={selectedProperty}
                    />

                    <Heading
                        size="md"
                        my=".5em"
                    >
                        {`${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`}
                    </Heading>

                    <PropertyDetails property={selectedProperty} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
