export const translations = {
    de: {
        button: {
            complete: "Vervollständigen",
        },
        customizeExperience: {
            customizeYourExperience: "Richten Sie die Plattform ein",
            completeProfile: {
                title: "Vervollständigen Sie Ihr Profil",
                subtext:
                    "Es fehlen Pflichtangaben zu Ihrem Unternehmen, bitte füllen Sie alle relevanten Felder aus.",
            },
            addTeamMembers: {
                title: "Teammitglieder hinzufügen",
                subtext:
                    "Fügen Sie gegebenenfalls Teammitglieder zu Ihrem Unternehmensprofil hinzu.",
            },
        },
        noDataPlaceholder: "Noch keine Anfragen erstellt.",
        noCaseEnquiries: "Aktuell keine Beauftragung.",
        noOfEntries: "Anzeigen",
        ongoingCasesWithCount: "Laufende Geschäftsfälle: {{count}}",
        recentEnquiriesWithCount: "Aktuelle Anfragen: {{count}}",
        caseEnquiries: "Beauftragungen: {{count}}",
        statistics: {
            cases: {
                active: "Aktiv",
                completed: "Abgeschlossen",
            },
            enquiries: {
                active: "Aktiv",
                avgOffers: "Durchschn. Anzahl Angebote",
                avgSelectionTime: "Durchschn. Auswahlzeit",
                days: "{{days}} Tage",
                hours: "{{hours}} Std",
                minutes: "{{minutes}} Min",
                overAMonth: "1 Monat+",
            },
        },
    },
    en: {
        button: {
            complete: "Complete",
        },
        customizeExperience: {
            customizeYourExperience: "Customize Your Experience",
            completeProfile: {
                title: "Complete your profile",
                subtext:
                    "You have missing mandatory information about your company, please complete all relevant fields.",
            },
            addTeamMembers: {
                title: "Add team members",
                subtext:
                    "Consider adding your team members to your company profile.",
            },
        },
        noDataPlaceholder: "No enquiries created yet.",
        noCaseEnquiries: "No case enquiries.",
        noOfEntries: "Show",
        ongoingCasesWithCount: "Ongoing Cases: {{count}}",
        recentEnquiriesWithCount: "Recent Enquiries: {{count}}",
        caseEnquiries: "Case Enquiries: {{count}}",
        statistics: {
            cases: {
                active: "Active",
                completed: "Completed",
            },
            enquiries: {
                active: "Active",
                avgOffers: "Avg. No of Offers",
                avgSelectionTime: "Avg. Selection Time",
                days: "{{days}} days",
                hours: "{{hours}} h ",
                minutes: "{{minutes}} min",
                overAMonth: "1 month+",
            },
        },
    },
};
