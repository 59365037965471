import { Icon, IconProps } from "@chakra-ui/icons";

const BookmarkCheckIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 14 19"
        {...props}
    >
        <path
            d="M0.5 17.6167V2.67445C0.5 2.16928 0.675 1.7417 1.025 1.3917C1.375 1.0417 1.80258 0.866699 2.30775 0.866699H11.6923C12.1974 0.866699 12.625 1.0417 12.975 1.3917C13.325 1.7417 13.5 2.16928 13.5 2.67445V17.6167L7 14.8282L0.5 17.6167Z"
            fill="#FFA100"
        />
        <path
            d="M10.5455 6.2917L5.95128 10.8859L3.45703 8.4167L4.52628 7.34745L5.95128 8.77245L9.47628 5.23795L10.5455 6.2917Z"
            fill="#1A202C"
        />
    </Icon>
);

export default BookmarkCheckIcon;
