import { MISSING_TRANSLATION } from "../../common/MISSING_TRANSLATION";
import { offerStatusTranslations } from "./offerStatus";

export const translations = {
    de: {
        ACTIVITIES: {
            reject: {
                NAME: "Ablehnen",
                confirmationBlurb: "Sind Sie sicher, dass Sie dieses Angebot ablehnen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
            rejectAll: {
                NAME: "Ablehnen",
                confirmationBlurb:
                    "Diese Angebote wirklich ablehnen? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
            withdraw: {
                NAME: "Zurückziehen",
                confirmationTitle: "Angebot zurückziehen",
                confirmationBlurb: "Dieses Angebot wirklich zurückziehen? ? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
        },
        awaitingOffers: {
            waitingForOffers: "Warte auf Angebote...",
            waitingForOffersBlurb1: "Wenn Angebote von Gutachtern eingereicht werden, sehen Sie dies hier.",
            waitingForOffersBlurb2: "Wir benachrichtigen Sie auch per E-Mail und das Benachrichtigungssystem auf der Plattform.",
        },
        availability: "Früheste Verfügbarkeit",
        comments: "Nachricht",
        commentsPlaceholder: "Optionale Nachricht an den Kunden",
        deliveryDate: "Fertigstellung",
        newOffer: "Neues Angebot",
        noCommentsPlaceholder: "[Keine Nachricht]",
        offers: "Angebote",
        piCoverAbsolute: "PI Cover [€]",
        piCoverPercentage: "PI Cover [%]",
        price: "Preis",
        submit: "Abgeben",
        status: offerStatusTranslations.de,
        statusContent: {
            ACCEPTED: MISSING_TRANSLATION,
            PENDING: MISSING_TRANSLATION,
            REJECTED: MISSING_TRANSLATION,
            WITHDRAWN: MISSING_TRANSLATION,
        },
        submittedAt: "Eingereicht",
        timeline: "Arbeitszeit",
    },
    en: {
        ACTIVITIES: {
            reject: {
                NAME: "Reject",
                confirmationBlurb: "Are you sure you want to reject this offer? This action cannot be undone.",
            },
            rejectAll: {
                NAME: "Reject",
                confirmationBlurb: "Reject all these offers? This action cannot be undone.",
            },
            withdraw: {
                NAME: "Withdraw",
                confirmationTitle: "Withdraw offer",
                confirmationBlurb: "Withdraw this offer? This action cannot be undone.",
            },
        },
        awaitingOffers: {
            waitingForOffers: "Waiting for offers...",
            waitingForOffersBlurb1:
                "When any offers are submitted by the valuers you will see them here.",
            waitingForOffersBlurb2:
                "We will also notify you by email and notification system.",
        },
        availability: "Earliest Availability",
        comments: "Message",
        commentsPlaceholder: "Optional message to the client",
        deliveryDate: "Completion",
        newOffer: "New Offer",
        noCommentsPlaceholder: "[No message]",
        offers: "Offers",
        piCoverAbsolute: "PI Cover [€]",
        piCoverPercentage: "PI Cover [%]",
        price: "Price",
        submit: "Submit",
        submittedAt: "Submitted",
        status: offerStatusTranslations.en,
        statusContent: {
            ACCEPTED: "Your offer accepted by client. Case {{caseId}} was created.",
            PENDING: "Your offer is pending.",
            REJECTED: "We're sorry, it seems that client decided to reject your offer  you can try again",
            WITHDRAWN: "You have withdrawn your offer for this Enquiry. You can submit new offer.",
        },
        timeline: "Delivery Time",
    },
};
