import {
    caseColumns,
    enquiryColumns, offerColumns, propertyColumns
} from "./components/data-table/columns/i18n";

import { offerEnrichments } from "./features/enrichment/domain/offer/enrichedOffersI18n";

export const common = {
    de: {
        button: {
            back: "Zurück",
            cancel: "Abbrechen",
            close: "Schließen",
            confirm: "Bestätigen",
            complete: "Vervollständigen",
            done: "Fertig",
            edit: "Bearbeiten",
            next: "Nächste",
            previous: 'Vorherige',
            sendLink: "Link senden",
            view: "Anzeigen",
            submit: "Abschicken",
        },
        components: {
            dataTable: {
                columns: {
                    caseColumns: caseColumns.de,
                    enquiryColumns: enquiryColumns.de,
                    propertyColumns: propertyColumns.de,
                    offerColumns: offerColumns.de,
                },
            },
        },
        features: {
            enrichment: {
                domain: {
                    offer: offerEnrichments.de,
                },
            },
        },
        email: "E-Mail Adresse",
        error: {
            error: "Fehler",
            weAreSorry: "Es tut uns leid!",
        },
        goToDashboard: "Zum Dashboard",
        loading: "Lädt...",
        "n/a": "N/A",
        noData: "Keine Daten vorhanden",
        password: "Passwort",
        showLess: "Weniger anzeigen",
        showMore: "Mehr anzeigen",
        toast: {},
        units: {
            days: "Tage",
            km: "km",
            years: "Jahre",
        },
        wvw: "WBW",
    },
    en: {
        button: {
            back: "Back",
            cancel: "Cancel",
            close: "Close",
            confirm: "Confirm",
            complete: "Complete",
            done: "Done",
            edit: "Edit",
            next: "Next",
            previous: 'Previous',
            sendLink: "Send Link",
            submit: "Submit",
            view: "View",
        },
        components: {
            dataTable: {
                columns: {
                    caseColumns: caseColumns.en,
                    enquiryColumns: enquiryColumns.en,
                    propertyColumns: propertyColumns.en,
                    offerColumns: offerColumns.en,
                },
            },
        },
        features: {
            enrichment: {
                domain: {
                    offer: offerEnrichments.en,
                },
            },
        },
        email: "Email Address",
        error: {
            error: "Error",
            weAreSorry: "We're sorry!",
        },
        goToDashboard: "Go to Dashboard",
        loading: "Loading...",
        "n/a": "N/A",
        noData: "No data present",
        password: "Password",
        showLess: "Show less",
        showMore: "Show more",
        toast: {},
        units: {
            days: "Days",
            km: "km",
            years: "years",
        },
        wvw: "WVW",
    },
};
