import { useDisclosure } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { OfferOnlyType } from "../../../../types";
import { offerWithdraw } from "../../../../utils/api/endpoints";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ParamsType = {
    offer: OfferOnlyType;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useOfferWithdraw = (params: ParamsType) => {
    const {
        offer: { id },
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { mutate: withdraw } = useMutation(
        (id: number) => offerWithdraw(id),
        {
            onSuccess: () => {
                onSuccess?.();
            },
            onError,
        }
    );

    const onConfirmWithdrawal = () => {
        onClose();
        withdraw(id);
    };

    const confirmationModal = (
        <ConfirmationModal
            isOpen={isOpen}
            onConfirmation={onConfirmWithdrawal}
            onClose={onClose}
            title={t("domain.offer.ACTIVITIES.withdraw.confirmationTitle")}
            blurb={t("domain.offer.ACTIVITIES.withdraw.confirmationBlurb")}
            confirmationButtonLabel={t("domain.offer.ACTIVITIES.withdraw.NAME")}
            confirmationButtonVariant={"negative"}
        />
    );

    return {
        update: onOpen,
        confirmationModal,
    };
};

export default useOfferWithdraw;
