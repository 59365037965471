import { OfferType } from "../../../../../../types";
import DataTable from "../../../../common/components/data-table/DataTable";
import { offerColumns } from "../../../../domain/offer/OfferColumns";

type OfferTableProps = {
    offers: OfferType[];
};

const AcceptedOfferTable = (props: OfferTableProps) => {
    const { offers } = props;

    const acceptedOffer = offers.filter((offer) => offer.status === 'ACCEPTED');

    return (
        <>
            <DataTable
                data={acceptedOffer}
                selectedRows={acceptedOffer}
                variant="AcceptedOfferVariant"
                columns={[
                    offerColumns.valuerProfile,
                    offerColumns.piCoverValue,
                    offerColumns.piCoverPercentage,
                    offerColumns.specialPi,
                    offerColumns.earliestAvailability,
                    offerColumns.workingTime,
                    offerColumns.delivery,
                    offerColumns.price,
                    offerColumns.viewCase,
                    offerColumns.view,
                ]}
            />
        </>
    );
};

export { AcceptedOfferTable };