import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { PICoverParams } from ".";
import { OfferOnlyType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { OfferCreationFormDataType } from "./OfferCreationFormOutput";
import { OfferDetails } from "./OfferDetails";
import { Editor, OfferEditorFormInitialValuesType } from "./OfferEditor";
import WithdrawOffer from "./WithdrawOffer";

type Props = {
    piCover: PICoverParams;
    createOffer: (draft: OfferCreationFormDataType) => any;
    onError: (error: Error) => void;
    readonly?: boolean;
} & (
    | {
          offer: OfferOnlyType;
          editOffer: (draft: OfferCreationFormDataType) => any;
          onWithdrawOffer: () => any;
      }
    | {
          offer: undefined;
          readonly?: false;
          editOffer?: undefined;
          onWithdrawOffer?: undefined;
      }
);

export const OfferComponent = (props: Props) => {
    const {
        offer,
        readonly,
        createOffer,
        editOffer,
        onWithdrawOffer,
        onError,
        piCover: { piCoverSpecialArrangementsAvailable },
        piCover,
    } = props;

    // render

    if (readonly) {
        return <OfferDetails offer={offer} />;
    }

    if (!offer) {
        const initialValues = createInitialValues(NEW_OFFER_DRAFT, piCover);

        return (
            <Editor
                onSubmit={createOffer}
                initialValues={initialValues}
                piCoverSpecialArrangementsAvailable={
                    piCoverSpecialArrangementsAvailable
                }
            />
        );
    }

    return (
        <OfferDisplay
            offer={offer}
            piCover={piCover}
            editOffer={editOffer}
            createOffer={createOffer}
            onWithdrawOffer={onWithdrawOffer}
            onError={onError}
        />
    );
};

type OfferDisplayProps = {
    offer: OfferOnlyType;
    editOffer: (draft: OfferCreationFormDataType) => any;
    createOffer: (draft: OfferCreationFormDataType) => any;
    onWithdrawOffer: () => any;
    onError: (error: Error) => void;
    piCover: PICoverParams;
};

const OfferDisplay = (props: OfferDisplayProps) => {
    const {
        offer: {
            price,
            piCoverValue,
            piCoverPercentage,
            timeline,
            availability,
            status,
            comments,
        },
        offer,
        editOffer,
        createOffer,
        onWithdrawOffer,
        onError,
        piCover: { piCoverSpecialArrangementsAvailable },
        piCover,
    } = props;

    const t = useSmartTranslation();

    const [editing, setEditing] = useState<"EDIT" | "NEW_OFFER" | false>(false);

    // render

    if (editing) {
        const cancelEditing = () => setEditing(false);

        const initialValues = createInitialValues(
            {
                price: price.toString(),
                piCoverPercentage: piCoverPercentage!.toString(),
                piCoverValue: piCoverValue!.toString(),
                timeline: timeline.toString(),
                availability: availability,
                comments: comments || "",
            },
            piCover
        );

        const onSubmit = editing === "EDIT" ? editOffer : createOffer;

        return (
            <Editor
                initialValues={initialValues}
                piCoverSpecialArrangementsAvailable={
                    piCoverSpecialArrangementsAvailable
                }
                onSubmit={(formdata: OfferCreationFormDataType) => {
                    onSubmit(formdata);
                    cancelEditing();
                }}
                onCancel={cancelEditing}
            />
        );
    }

    let buttons;

    switch (status) {
        case "ACCEPTED": {
            break;
        }
        case "PENDING": {
            const startEditing = (
                <Button
                    flexGrow={1}
                    key={"startEditing"}
                    onClick={() => setEditing("EDIT")}
                    variant={"outline"}
                >
                    {t("common.button.edit")}
                </Button>
            );

            const withdraw = (
                <WithdrawOffer
                    offer={offer}
                    onSuccess={onWithdrawOffer}
                    onError={onError}
                    flexGrow={1}
                    key={"withdraw"}
                />
            );

            buttons = [startEditing, withdraw];
            break;
        }
        case "REJECTED":
        case "WITHDRAWN": {
            const startEditing = (
                <Button
                    flexGrow={1}
                    key={"startEditing"}
                    onClick={() => setEditing("EDIT")}
                    variant={"outline"}
                >
                    {t("domain.offer.newOffer")}
                </Button>
            );

            buttons = [startEditing];
            break;
        }
    }

    return (
        <OfferDetails
            offer={offer}
            buttons={buttons}
        />
    );
};

const NEW_OFFER_DRAFT = {
    price: "",
    piCoverValue: "",
    piCoverPercentage: "",
    timeline: "",
    availability: "",
    comments: "",
};

const createInitialValues = (
    template: OfferEditorFormInitialValuesType,
    piCoverParams: PICoverParams
) => {
    const {
        defaultPICoverPercentage,
        defaultPICoverValue,
        piCoverSpecialArrangementsAvailable,
    } = piCoverParams;

    const initialValues = {
        ...template,
    };

    if (!piCoverSpecialArrangementsAvailable) {
        initialValues.piCoverValue = defaultPICoverValue.toString();
        initialValues.piCoverPercentage = defaultPICoverPercentage.toString();
    }

    return initialValues;
};
