import { Button } from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useEnquirySetDormant from "./useEnquirySetDormant";

type ParamsType = {
    enquiry: EnquiryType;
    dormantForUser: boolean;
    userIsClient: boolean;
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const ArchiveEnquiry = (params: ParamsType) => {
    const {
        enquiry: { status },
        enquiry,
        dormantForUser,
        userIsClient,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { update: archiveEnquiry, confirmationModal } = useEnquirySetDormant({
        onSuccess,
        onError,
        enquiry,
    });

    const canArchive = userIsClient
        ? (status === "ACCEPTED" || status === "CANCELLED") && !dormantForUser
        : !dormantForUser;

    return (
        <Button
            isDisabled={!canArchive}
            onClick={() => archiveEnquiry()}
        >
            {t("domain.enquiry.ACTIVITIES.archive.NAME")}
            {confirmationModal}
        </Button>
    );
};

export default ArchiveEnquiry;
