import { EnquiryType } from "../../../../types";
import { testProperties } from "../property/testdata";
import { EnquiryMatchInfo } from "./enquiryRequirements/EnquiryMatchInfo";

export const testEnquiry: EnquiryType = {
    id: 1234,
    status: "ACCEPTED",
    valuationType: "Full Valuation",
    valuationStandards: "HypZert",
    completionDate: "2024-12-31",
    valuerExperience: "5",
    valuerMinimumPICover: 100000,
    valuerMinimumPICoverPercentage: 30,
    properties: testProperties,
    valuationPurposes: ["Divorce/Inheritance"],
    additionalRequirements:
        "Please upload certificates outlining your experience along with your availability for soonest possible start date.",
} as EnquiryType;

export const testEnquiry2: EnquiryType = {
    id: 1235,
    fromEntityId: 1,
    fromUserId: 12,
    toEntityIds: [2],
    toUserIds: [13],
    status: "PENDING",
    valuationType: "Full Valuation",
    valuationSpecial: ["Asbestos", "Roof"],

    valuationStandards: "DIN 17024",
    valuationStandardsOther: "",

    valuationPurposes: ["Acquisition/Sale", "Tax", "Court"],
    valuationPurposesOther: "",

    valuerExperience: "3",
    valuerMinimumPartners: "2",
    valuerMaxOfficeDistance: "20",
    valuerMinimumPICover: 2000000,
    valuerMinimumPICoverPercentage: 70,

    completionDate: "2025-11-21",
    additionalRequirements: "Please add additional certifications",

    invoiceFName: "John",
    invoiceLName: "Doe",
    invoiceEmail: "JD@client.com",
    invoiceEmailCc: "JD@client.com",
    invoiceMobileDialCode: "+46",
    invoiceMobile: "123456789",

    billingStreet: "10 Street Name",
    billingCity: "City",
    billingCountry: "Country",
    billingPostalCode: "1234",

    properties: testProperties,
} as EnquiryType;

// standards and other empty, like for a direct enquiry by private client
export const testEnquiry3: EnquiryType = {
    id: 1235,
    fromEntityId: 1,
    fromUserId: 12,
    toEntityIds: [2],
    toUserIds: [13],
    status: "PENDING",
    valuationType: "Full Valuation",
    valuationSpecial: ["Asbestos", "Roof"],

    valuationStandards: "",
    valuationStandardsOther: "",

    valuationPurposes: ["Aquisition/Sale", "Tax", "Court"],
    valuationPurposesOther: "",

    valuerExperience: "3",
    valuerMinimumPartners: "2",
    valuerMaxOfficeDistance: "20",
    valuerMinimumPICover: 2000000,
    valuerMinimumPICoverPercentage: 70,

    completionDate: "2025-11-21",
    additionalRequirements: "Please add additional certifications",

    invoiceFName: "John",
    invoiceLName: "Doe",
    invoiceEmail: "JD@client.com",
    invoiceEmailCc: "JD@client.com",
    invoiceMobileDialCode: "+46",
    invoiceMobile: "123456789",

    billingStreet: "10 Street Name",
    billingCity: "City",
    billingCountry: "Country",
    billingPostalCode: "1234",

    properties: testProperties,
} as EnquiryType;

// valuationType Other + valuationOther
export const testEnquiry4: EnquiryType = {
    id: 1235,
    fromEntityId: 1,
    fromUserId: 12,
    toEntityIds: [2],
    toUserIds: [13],
    status: "PENDING",
    valuationType: "Other Valuation",
    valuationOther: "(untranslatable) Some other valuation type",
    valuationSpecial: ["Asbestos", "Roof"],

    valuationStandards: "DIN 17024",
    valuationStandardsOther: "",

    valuationPurposes: ["Aquisition/Sale", "Tax", "Court"],
    valuationPurposesOther: "",

    valuerExperience: "3",
    valuerMinimumPartners: "2",
    valuerMaxOfficeDistance: "20",
    valuerMinimumPICover: 2000000,
    valuerMinimumPICoverPercentage: 70,

    completionDate: "2025-11-21",
    additionalRequirements: "Please add additional certifications",

    invoiceFName: "John",
    invoiceLName: "Doe",
    invoiceEmail: "JD@client.com",
    invoiceEmailCc: "JD@client.com",
    invoiceMobileDialCode: "+46",
    invoiceMobile: "123456789",

    billingStreet: "10 Street Name",
    billingCity: "City",
    billingCountry: "Country",
    billingPostalCode: "1234",

    properties: testProperties,
} as EnquiryType;

// other purposes
export const testEnquiry5: EnquiryType = {
    id: 1235,
    fromEntityId: 1,
    fromUserId: 12,
    toEntityIds: [2],
    toUserIds: [13],
    status: "PENDING",
    valuationType: "Full Valuation",
    valuationSpecial: ["Asbestos", "Roof"],

    valuationStandards: "DIN 17024",
    valuationStandardsOther: "",

    valuationPurposes: ["Other"],
    valuationPurposesOther: "(untranslatable) some other purpose",

    valuerExperience: "3",
    valuerMinimumPartners: "2",
    valuerMaxOfficeDistance: "20",
    valuerMinimumPICover: 2000000,
    valuerMinimumPICoverPercentage: 70,

    completionDate: "2025-11-21",
    additionalRequirements: "(untranslatable) Please add additional certifications",

    invoiceFName: "John",
    invoiceLName: "Doe",
    invoiceEmail: "JD@client.com",
    invoiceEmailCc: "JD@client.com",
    invoiceMobileDialCode: "+46",
    invoiceMobile: "123456789",

    billingStreet: "10 Street Name",
    billingCity: "City",
    billingCountry: "Country",
    billingPostalCode: "1234",

    properties: testProperties,
} as EnquiryType;

export const testEnquiries: EnquiryType[] = [
    testEnquiry,
    testEnquiry2,
    testEnquiry3,
    testEnquiry4,
    testEnquiry5,
] as EnquiryType[];

export const testOfferMatching: EnquiryMatchInfo = {
    valuationStandards: true,
    valuerExperience: true,
    valuerMinimumPartners: true,
    valuerMaxOfficeDistance: true,
    valuerPICover: {
        matches: false,
        specialArrangementsAvailable: true,
    },
};
