import {
    Box,
    Divider,
    HStack,
    VStack,
    Text,
    SimpleGrid,
} from "@chakra-ui/react";
import { PropertyType } from "../../../../types";
import { ReactNode } from "react";
import { estimatePortfolioValue } from "../../common/components/data-table/TableDataCalculations";
import {
    calculateAveragePropertyArea,
    createPropertyTypesCount,
    calcPropertyAreaMinMax,
    calcPropertyValueMinMax,
} from "./PropertyPortfolioCalulations";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import delimitNumber from "../../../../common/functions/delimitNumber";
import { StandardPropertyTypeWithMixed } from "../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";

type Props = {
    properties: PropertyType[];
};

export const PropertyPortfolioSummary = (props: Props) => {
    const { properties } = props;

    const t = useSmartTranslation();

    //amount of properties
    const noOfProperties = properties.length;
    const noOfPropertyTypes = createPropertyTypesCount(properties);
    const amountOfProperties = (
        <SummaryItemContainer
            title={t(
                "domain.property.propertyPortfolio.summary.ammountOfProperties"
            )}
        >
            <Text
                fontWeight="bold"
                fontSize="20px"
            >
                {t(
                    "domain.property.propertyPortfolio.summary.noOfProperties",
                    { number: noOfProperties }
                )}
            </Text>
            <Divider />
            <VStack
                align="left"
                spacing={0}
            >
                {Object.entries(noOfPropertyTypes).map(([key, value]) => {
                    const typeKey = key as StandardPropertyTypeWithMixed;
                    return (
                        <HStack key={typeKey}>
                            <Text
                                width="10%"
                                fontWeight="bold"
                            >
                                {value}x
                            </Text>
                            <Text>
                                {t(`domain.standards.propertyType.${typeKey}`)}
                            </Text>
                        </HStack>
                    );
                })}
            </VStack>
        </SummaryItemContainer>
    );

    // average property area
    const averageArea = calculateAveragePropertyArea(properties);
    const { smallest, biggest } = calcPropertyAreaMinMax(properties);

    const averagePropertyArea = (
        <SummaryItemContainer
            title={t(
                "domain.property.propertyPortfolio.summary.avgPropertyArea"
            )}
        >
            <Text
                fontWeight="bold"
                fontSize="20px"
            >
                {delimitNumber(averageArea)} m²
            </Text>
            <Divider />
            <VStack
                align="left"
                spacing={0}
                w="100%"
            >
                <HStack>
                    <Text
                        width="33%"
                        fontWeight="bold"
                    >
                        {delimitNumber(smallest)} m²
                    </Text>

                    <Text>
                        {t(
                            "domain.property.propertyPortfolio.summary.smallest"
                        )}
                    </Text>
                </HStack>
                <HStack>
                    <Text
                        width="33%"
                        fontWeight="bold"
                    >
                        {delimitNumber(biggest)} m²
                    </Text>

                    <Text>
                        {" "}
                        {t(
                            "domain.property.propertyPortfolio.summary.biggest"
                        )}
                    </Text>
                </HStack>
            </VStack>
        </SummaryItemContainer>
    );

    // estimate portfolio value
    const estimateValue = estimatePortfolioValue(properties);
    const { cheapest, mostExpensive } = calcPropertyValueMinMax(properties);
    const estPortfolioValue = (
        <SummaryItemContainer
            title={t(
                "domain.property.propertyPortfolio.summary.estimatePortfolioValue"
            )}
        >
            <Text
                fontWeight="bold"
                fontSize="20px"
                color="cyan.500"
            >
                €{delimitNumber(estimateValue)}
            </Text>
            <Divider />
            <VStack
                align="left"
                spacing={0}
                w="100%"
            >
                <HStack>
                    <Text
                        width="25%"
                        fontWeight="bold"
                        color="cyan.500"
                    >
                        €{delimitNumber(cheapest)}
                    </Text>

                    <Text>
                        {t(
                            "domain.property.propertyPortfolio.summary.cheapest"
                        )}
                    </Text>
                </HStack>
                <HStack>
                    <Text
                        width="25%"
                        fontWeight="bold"
                        color="cyan.500"
                    >
                        €{delimitNumber(mostExpensive)}
                    </Text>

                    <Text>
                        {t(
                            "domain.property.propertyPortfolio.summary.mostExpensive"
                        )}
                    </Text>
                </HStack>
            </VStack>
        </SummaryItemContainer>
    );

    return (
        <SimpleGrid
            height="100%"
            minH="10em"
            w="100%"
            columns={3}
            spacing={2}
        >
            {amountOfProperties}

            {averagePropertyArea}

            {estPortfolioValue}
        </SimpleGrid>
    );
};

//helpers

type SummaryItemContainerProps = {
    title: string;
    children: ReactNode;
};

const SummaryItemContainer = (props: SummaryItemContainerProps) => {
    const { title, children } = props;
    return (
        <Box
            padding="1em"
            minW="10em"
            w="100%"
            bg="gray.50"
            borderRadius="8"
        >
            <VStack align="left">
                <Text
                    fontSize="12px"
                    variant="secondary"
                >
                    {title}
                </Text>
                <VStack align="left">{children}</VStack>
            </VStack>
        </Box>
    );
};
