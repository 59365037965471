export const translations = {
    de: {
        institutionalClient: "Institutioneller Kunde",
        obscuredClient: "Anonymisierter Kunde",
        at: " bei ",
        privateClient: "Privatkunde",
    },
    en: {
        institutionalClient: "Institutional Client",
        obscuredClient: "Obscured Client",
        at: " at ",
        privateClient: "Private End Customer",
    },
};
