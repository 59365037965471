import {
    Box,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";
import { ArrangeVertical } from "iconsax-react";
import { ReactNode, useEffect, useState } from "react";
import { CheckedColumn } from "./CheckedColumn";
import {
    TranslationFunction,
    useSmartTranslation
} from "../../hooks/useSmartTranslation";
import SelectArray, { OptionType } from "../SelectArray";

export type DataTableColumnType<T> = {
    alignment?: string;
    title?: (t: TranslationFunction) => string;
    dataIndex?: string;
    maxWidth?: string;
    sorter?: (a: T, b: T) => number;
    render?: (data: T, t: TranslationFunction) => ReactNode;
};

export type DataTableFilterType<T> = {
    placeholder: string;
    options: OptionType[];
    filterName: string;
    filterFunction: (i: T, v: string | undefined) => boolean;
};

// FIXME how to use sorting from props in state
// FIXME we could probably make a type from string-identifiers on columns and use that in the SortOrder
type SortOrder = {
    column: number | undefined;
    direction?: "default" | "asc" | "desc";
};

type PropTypes<T> = {
    columns: DataTableColumnType<T>[];
    data: T[];
    noOfEntriesToShow?: number;
    noDataText?: string;
    tableWidth?: string;
    initialSorting?: SortOrder;
    filters?: DataTableFilterType<T>[];
    hoverable?: boolean;
    selectedRows?: T[];
    onRowClick?: (item: T) => void;
    onToggleRowChecked?: (item: T, checked: boolean) => void;
    checkedRows?: T[];
    footerContent?: ReactNode | ReactNode[];
    variant?: string;
};

type TableRowProps<T> = {
    columns: DataTableColumnType<T>[];
    item: T;
    isSelected: boolean | undefined;
    isChecked: boolean | undefined;
    onToggleRowChecked?: (item: T, checked: boolean) => void;
    onRowClick?: (item: T) => void;
};

const TableRow = <T extends Record<string, any>>(props: TableRowProps<T>) => {
    const {
        columns,
        item,
        isSelected,
        onRowClick,
        isChecked,
        onToggleRowChecked,
    } = props;

    const t = useSmartTranslation();

    let checkedColumn;
    if (onToggleRowChecked && isChecked !== undefined) {
        const onToggle = (newCheckedState: boolean) =>
            onToggleRowChecked(item, newCheckedState);
        checkedColumn = (
            <Td key={"checked"}>
                <Box display="flex">
                    {CheckedColumn.render(isChecked, onToggle)}
                </Box>
            </Td>
        );
    }

    return (
        <Tr
            aria-selected={isSelected}
            onClick={() => onRowClick?.(item)}
            cursor={onRowClick && "pointer"}
        >
            {checkedColumn}
            {columns.map((col, index) => (
                <Td
                    key={index}
                    maxWidth={col.maxWidth}
                >
                    <Box
                        justifyContent={col.alignment}
                        display="flex"
                    >
                        {col.render && col.render(item, t)}

                        {!col.render && item?.[col.dataIndex || ""]}
                    </Box>
                </Td>
            ))}
        </Tr>
    );
};

const DataTable = <T extends Record<string, any>>(props: PropTypes<T>) => {
    const {
        columns,
        data,
        noOfEntriesToShow,
        noDataText,
        tableWidth,
        initialSorting = {
            // FIXME why do I have to put defaults when I have defaultProps
            column: undefined,
            direction: "default",
        },
        filters,
        hoverable,
        selectedRows,
        onRowClick,
        onToggleRowChecked,
        checkedRows,
        footerContent,
        variant,
    } = props;

    const t = useSmartTranslation();

    const [sortedData, setSortedData] = useState<T[]>(data);

    const [sort, setSort] = useState<SortOrder>(initialSorting);

    const [filterState, setFilterState] = useState<{
        [index: string]: string | undefined;
    }>(
        filters?.reduce(
            (res, i) => ({ ...res, [i.filterName]: undefined }),
            {}
        ) || {}
    );

    useEffect(() => {
        const { column, direction } = sort;

        let newData = [...data];

        if (column !== undefined && columns[column] && columns[column].sorter) {
            const { sorter } = columns[column];
            newData = [...data].sort(sorter);

            if (direction === "desc") {
                newData = newData.reverse();
            }
        }

        filters?.forEach((i) => {
            const filterValue = filterState?.[i.filterName];
            if (filterValue) {
                newData = newData.filter((row) =>
                    i.filterFunction(row, filterValue)
                );
            }
        });

        setSortedData([...newData]);
    }, [data, sort, filterState]);

    if (variant && (hoverable || onRowClick)) {
        throw new Error("API doesn't support this combination");
    }

    const sortColumn = (index: number) => {
        let sortDirection = sort.direction;

        if (sort.column !== index) {
            setSort({
                column: index,
                direction: "asc",
            });

            return;
        }

        switch (sortDirection) {
            case "asc":
                sortDirection = "desc";
                break;
            case "desc":
                sortDirection = "default";
                break;
            default:
                sortDirection = "asc";
                break;
        }

        setSort({
            column: index,
            direction: sortDirection,
        });
    };

    if (!sortedData.length) {
        return (
            <Box
                backgroundColor="gray.50"
                borderRadius="8px"
                w="100%"
            >
                <Text
                    paddingBlock="2rem"
                    textAlign="center"
                    variant="secondary"
                    w="100%"
                >
                    {noDataText || t("common.noData")}
                </Text>
            </Box>
        );
    }

    let checkedColumn;
    if (onToggleRowChecked && checkedRows) {
        checkedColumn = (
            <Th key={"checked"}>
                <Box
                    alignItems="center"
                    display="flex"
                >
                    {CheckedColumn.title(checkedRows.length, data.length)}
                </Box>
            </Th>
        );
    }

    const footer = footerContent && (
        <HStack padding={"1em 1.5em"}>{footerContent}</HStack>
    );

    return (
        <>
            {filters && (
                <SelectArray
                    selectList={filters.map((i) => ({
                        placeholder: i.placeholder,
                        options: i.options,
                        value: filterState[i.filterName],
                        onChange: (selectedValue) =>
                            setFilterState({
                                ...filterState,
                                [i.filterName]: selectedValue,
                            }),
                    }))}
                />
            )}

            <VStack
                align={"start"}
                w={"100%"}
            >
                <TableContainer
                    borderRadius="8px"
                    borderWidth="1px"
                    borderColor="gray.100"
                    maxWidth={tableWidth}
                    w="100%"
                    className="hide-scrollbar hide-scrollbar::-webkit-scrollbar"
                >
                    <Table
                        variant={
                            hoverable || onRowClick ? "hoverable" : variant ? variant : "default"
                        }
                    >
                        <Thead>
                            <Tr>
                                {checkedColumn}
                                {columns.map((col, index) => (
                                    <Th
                                        key={index}
                                        maxW={col.maxWidth}
                                    >
                                        <Box
                                            alignItems="center"
                                            justifyContent={col.alignment}
                                            display="flex"
                                        >
                                            <Text
                                                _hover={{
                                                    cursor: col.sorter
                                                        ? "pointer"
                                                        : "normal",
                                                }}
                                                onClick={() =>
                                                    sortColumn(index)
                                                }
                                                variant="secondary"
                                            >
                                                {col.title && col.title(t)}
                                                {!col.title && ""}
                                            </Text>

                                            {col.sorter && (
                                                <IconButton
                                                    aria-label="Sort"
                                                    icon={
                                                        <ArrangeVertical size="18" />
                                                    }
                                                    color="blue.500"
                                                    size="xs"
                                                    variant="ghost"
                                                    _hover={{
                                                        color: "wvwGrey80",
                                                    }}
                                                    onClick={() =>
                                                        sortColumn(index)
                                                    }
                                                />
                                            )}
                                        </Box>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>

                        <Tbody>
                            {sortedData
                                ?.slice(0, noOfEntriesToShow)
                                .map((i, index) => (
                                    <TableRow
                                        columns={columns}
                                        item={i}
                                        key={index}
                                        isSelected={selectedRows?.includes(i)}
                                        onRowClick={onRowClick}
                                        onToggleRowChecked={onToggleRowChecked}
                                        isChecked={checkedRows?.includes(i)}
                                    />
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                {footer}
            </VStack>
        </>
    );
};

DataTable.defaultProps = {
    noDataText: undefined,
    tableWidth: "100%",
    initialSorting: {
        column: undefined,
        direction: "default",
    },
    filters: undefined,
    hoverable: false,
    selectedRows: undefined,
    variant: undefined,
};

export default DataTable;
