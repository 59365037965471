import { array, InferType, number, object, ObjectSchema, string } from "yup";
import { OfferCreateDto } from "../../../../utils/api/dto";

export const stringMustBeInteger = (val: string) =>
    val.replace(/[0-9]/g, "").length == 0;

export const OfferCreationFormData: ObjectSchema<
    Omit<
        OfferCreateDto,
        | "enquiryId"
        | "toEntityId"
        | "fromValuerId"
        | "fromEntityId"
        | "piCoverCurrency"
    >
> = object({
    price: number().min(1).required("forms.errors.required"),
    piCoverValue: number().min(0).required("forms.errors.required"),
    piCoverPercentage: number().min(0).required("forms.errors.required"),
    timeline: string()
        .required("forms.errors.required")
        .test("mustBeNumber", "forms.errors.enterActualNumber", stringMustBeInteger),
    availability: string().required("forms.errors.required"),
    comments: string().optional(),
    requiredAttachments: array().optional(),
    otherAttachments: array().optional(),
});

export type OfferCreationFormDataType = InferType<typeof OfferCreationFormData>;
