import {
    Form,
    Formik,
    FormikValues,
    FormikHelpers,
} from 'formik';
import {
    ReactNode,
    createContext,
    useCallback,
} from 'react';
import { ObjectSchema } from 'yup';

type PropTypes = {
    children: ReactNode[] | ReactNode;
    initialValues: FormikValues;
    submitting?: boolean;
    validationSchema?: ObjectSchema<any>;
    onSubmit: (values: FormikValues, formikHelpers?: FormikHelpers<FormikValues>) => void;
};

type ValidationContextType = (fieldName: string) => boolean;

export const FormikValidationContext = createContext<ValidationContextType>(() => false);

const FormikForm = (props: PropTypes) => {
    const {
        children,
        initialValues,
        submitting,
        validationSchema,
        onSubmit,
    } = props;

    const isFieldRequired = useCallback((fieldName: string) => {
        const requiredFields: string[] = [];

        if (validationSchema && validationSchema.fields) {
            Object.keys(validationSchema.fields).forEach(key => {
                const item = validationSchema.fields[key] as any;

                if (item.exclusiveTests.required) {
                    requiredFields.push(key);
                }
            });
        }

        return requiredFields.includes(fieldName);
    }, [validationSchema]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(...params) => {
                if (submitting) return;

                onSubmit(...params);
            }}
        >
            <FormikValidationContext.Provider value={isFieldRequired}>
                <Form style={{ width: '100%' }}>
                    {children}
                </Form>
            </FormikValidationContext.Provider>
        </Formik>
    );
};

FormikForm.defaultProps = {
    validationSchema: undefined,
    submitting: false,
};

export default FormikForm;
