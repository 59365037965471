export const translations = {
    de: {
        offerDetailsTitle: "Angebots-Details",
        valuerCompetencesTitle: "Gutachter-Kompetenzen",
        valuerCompanyDetailsTitle: "Gutachter-Firmen-Details",
        valuerDetailsTitle: "Gutachter-Details",
        valuerQualificationsTitle: "Qualifikationen",
    },
    en: {
        offerDetailsTitle: "Offer Details",
        valuerCompetencesTitle: "Valuer Competences",
        valuerCompanyDetailsTitle: "Valuer Company Details",
        valuerDetailsTitle: "Valuer Details",
        valuerQualificationsTitle: "Qualifications",
    },
};
