import { PropertyType } from "../../../../types";

export const testProperty1: PropertyType = {
    id: 1,
    street: "Wedeler Landstraße 16-19",
    postalCode: "22559",
    city: "Hamburg",
    country: "DE",
    buildYear: 2022,
    propertyCondition: "NEW",
    propertyTypes: ["Commercial", "Land"],
    areaOverall: 120,
    areaGrossInternal: 80,
    estimateValue: 786000,
} as PropertyType;

export const testProperty2: PropertyType = {
    id: 2,
    street: "Am Hang 9",
    postalCode: "22557",
    city: "Hamburg",
    country: "DE",
    buildYear: 2000,
    propertyCondition: "RECENTLY_REFURBISHED",
    propertyTypes: ["Residential", "Land", "Commercial"],
    areaOverall: 800,
    areaGrossInternal: 234,
    estimateValue: 2000000,
} as PropertyType;

export const testProperties: PropertyType[] = [testProperty1, testProperty2];
