
export const translations = {
    de: {
        account: "Nutzerkonto",
        createEnquiry: {
            sendLink: "Link schicken",
        },
        dashboard: "Dashboard",
        logout: "Abmelden",
        profile: "Profil",
        enquiries: "Anfragen",
        cases: "Geschäftsfälle",
        system: "System",
        entities: "Unternehmen",
        users: "Nutzer",
        inspections: "Besichtigung",
    },
    en: {
        account: "Account",
        createEnquiry: {
            sendLink: "Send Link",
        },
        dashboard: "Dashboard",
        logout: "Logout",
        profile: "Profile",
        enquiries: "Enquiries",
        cases: "Cases",
        system: "System",
        entities: "Entities",
        users: "Users",
        inspections: "Inspections",
    },
};
