import { Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useMyEntity } from "../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import storage from "../../../../../utils/storage/storage";
import UploadCertificate from "../../../../../common/components/UploadCertificate";
import { StandardCompanyDocumentType } from "../../../../../common/vars/valuationsAndMembershipTypes/standardCompanyDocumentTypes";

type PropsType = {
    title: string;
    documentType: StandardCompanyDocumentType;
};

const EditCompanyDocsSection = (props: PropsType) => {
    const { title, documentType } = props;

    const [loadingUpload, setLoadingUpload] = useState(false);

    const {
        data: entity,
        isLoading,
        entityCertificates = [],
        entityCertificatesIsLoading,
        entityCertificatesRefresh,
    } = useMyEntity();

    if (isLoading || entityCertificatesIsLoading || !entity) {
        return <LoadingSpinner />;
    }

    const files = entityCertificates
        .filter((i) => i.type.toLowerCase() === documentType)
        .map((i) => ({
            name: i.label,
            fileUrl: i.file.fullPath,
        }));

    return (
        <VStack
            align="start"
            backgroundColor="wvwGrey05"
            borderRadius="8"
            padding="1rem"
            spacing="4"
            w="100%"
        >
            <Text color="wvwGreen">{title}</Text>

            <UploadCertificate
                files={files}
                loading={loadingUpload}
                onFileDelete={(fileUrl) => {
                    storage
                        .deleteUserFile(fileUrl)
                        .then(entityCertificatesRefresh);
                }}
                onRename={(fileUrl, label) => {
                    storage
                        .updateFileMetadata(fileUrl, label)
                        .then(entityCertificatesRefresh);
                }}
                onUpload={(fileList) => {
                    setLoadingUpload(true);

                    fileList.forEach((file) => {
                        storage
                            .uploadEntityCertificate(
                                entity.id,
                                file,
                                documentType
                            )
                            .then(entityCertificatesRefresh)
                            .finally(() => setLoadingUpload(false));
                    });
                }}
            />
        </VStack>
    );
};

export default EditCompanyDocsSection;
