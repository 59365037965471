export const translations = {
    de: {
        browseKeyword: "durchsuchen",
        dragDropBrowse: "Drag & Drop oder lokale Dateien durchsuchen",
        file: "Datei",
        fileNameRequired: "Dateiname ist erforderlich",
        fileTooLarge: "Datei ist zu groß",
        fileTypeNotAllowed: "Dateityp wird nicht unterstützt",
        maxFileSize: "Maximale Dateigröße ist 25 MB",
        button: {
            upload: "Hochladen",
            uploadFile: "Datei Hochladen",
        },
    },
    en: {
        browseKeyword: "browse",
        dragDropBrowse: "Drag and drop files, or browse",
        file: "File",
        fileNameRequired: "File name is required",
        fileTooLarge: "File is too large",
        fileTypeNotAllowed: "File type is not allowed",
        maxFileSize: "Max file size 25 MB",
        button: {
            upload: "Upload",
            uploadFile: "Upload File",
        },
    },
};
