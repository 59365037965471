import { Heading, VStack } from "@chakra-ui/react";
import { clamp } from "lodash";
import { useEffect, useState } from "react";
import { EnquiryType, PropertyType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Property } from "../../../../domain";
import { EnquiryMatchInfo } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import { EnquiryRequirements } from "../../../../domain/enquiry/enquiryRequirements/EnquiryRequirements";
import { testOffers } from "../../../../domain/offer/testdata";
import { PropertyDetails } from "../../../../domain/property/PropertyDetails";
import { Section } from "../../../../layout";
import { CompanyOffers } from "./CompanyOffers";
import { PropertyPortfolioSummary } from "../../../../domain/property/PropertyPortfolioSummary";

const translationNS = "screens.platform.valuer.valuerEnquiryDetails";

type Props = {
    enquiry: EnquiryType;
    matchInfo: EnquiryMatchInfo;
    device: "tablet" | "desktop" | undefined;
    myOffer: React.ReactNode;
};

const MultiPropertyLayout = (props: Props) => {
    const {
        enquiry: { properties },
        enquiry,
        matchInfo,
        device,
        myOffer,
    } = props;

    const t = useSmartTranslation();

    const selectInitalProperty = device === "desktop";

    const [selectedProperty, setSelectedProperty] = useState<
        PropertyType | undefined
    >(selectInitalProperty ? properties[0] : undefined);

    const selectedPropertyIndex = properties.findIndex(
        (i) => i.id === selectedProperty?.id
    );

    const requestedValuation = (
        <Section
            title={t("domain.enquiry.requestedValuationTitle")}
            content={
                <EnquiryRequirements
                    enquiry={enquiry}
                    matchInfo={matchInfo}
                />
            }
        />
    );

    const propertyPortfolio = (
        <Section
            title={t('domain.property.propertyPortfolio.title')}
            content={
                <VStack
                    w="100%"
                    spacing="4"
                >
                    <PropertyPortfolioSummary properties={properties} />
                    <Property.PortfolioTable
                        properties={properties}
                        selectedProperty={selectedProperty}
                        onRowClick={(item) => {
                            setSelectedProperty(item);
                        }}
                    />
                </VStack>
            }
        />
    );

    const map = (
        <Section
            content={
                <Property.PropertiesMap
                    properties={properties}
                    selectedProperty={selectedProperty}
                />
            }
        />
    );

    const propertyDetails = selectedProperty && (
        <Section
            title={
                device === "tablet"
                    ? t(`domain.property.propertyDetails.title`)
                    : `${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`
            }
            content={
                <>
                    {device === "tablet" && (
                        <>
                            <Property.PropertiesMap
                                properties={properties}
                                selectedProperty={selectedProperty}
                            />
                            <Heading
                                size="md"
                                my=".5em"
                            >
                                {`${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`}
                            </Heading>
                        </>
                    )}

                    <PropertyDetails property={selectedProperty} />
                </>
            }
        />
    );

    const offers = testOffers;

    const companyOffers = (
        <Section
            title={t(`${translationNS}.companyOffers.title`, {
                nCompanyOffers: 2,
            })}
            content={<CompanyOffers offers={offers} />}
        />
    );

    useEffect(() => {
        if (device === "tablet") return;
        if (selectedProperty !== undefined) return;

        setSelectedProperty(properties[0]);
    }, [device]);

    return (
        <>
            <VStack>
                {requestedValuation}
                {propertyPortfolio}
            </VStack>

            {device === "desktop" && (
                <VStack>
                    {map}
                    {propertyDetails}
                </VStack>
            )}

            {device === "tablet" && (
                <Property.PropertyDrawer
                    properties={properties}
                    selectedProperty={selectedProperty}
                    onClose={() => setSelectedProperty(undefined)}
                    onNextProperty={() => {
                        const nextIndex = clamp(
                            selectedPropertyIndex + 1,
                            0,
                            properties.length - 1
                        );

                        setSelectedProperty(properties[nextIndex]);
                    }}
                    onPreviousProperty={() => {
                        const nextIndex = clamp(
                            selectedPropertyIndex - 1,
                            0,
                            properties.length - 1
                        );

                        setSelectedProperty(properties[nextIndex]);
                    }}
                />
            )}

            <VStack>
                {companyOffers}
                {myOffer}
            </VStack>
        </>
    );
};

export default MultiPropertyLayout;
