import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";

export const translations = {
    de: {
        activeCasesWithCount: "Aktive Geschäftsfälle: {{count}}",
        customizeExperience: {
            addTeamMembers: {
                title: "Teammitglieder hinzufügen",
                subtext:
                    "Es fehlen Pflichtangaben zu Ihrem Unternehmen, bitte füllen Sie alle relevanten Felder aus.",
            },
            completeProfile: {
                title: "Profil vervollständigen",
                subtext:
                    "Fügen Sie gegebenenfalls Teammitglieder zu Ihrem Unternehmensprofil hinzu.",
            },
            customizeYourExperience: "Passe deine Erfahrung an",
            uploadMarketing: {
                title: "Marketingmaterial hochladen",
                subtext:
                    "Marketingmaterial erhöht die Transparenz bei Ihren Kunden und kann sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
            uploadPiCover: {
                // Todo: PI-Cover
                title: "PI-Cover hochladen",
                subtext:
                    "Diese Nachweisdokumente erhöhen die Transparenz bei Ihren Kunden und können sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
            uploadValuationSample: {
                title: "Musterwertgutachten hochladen",
                subtext:
                    "Musterwertgutachten erhöhen die Transparenz bei Ihren Kunden und können sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
        },
        noAcceptedStartedValuation:
            "Keine akzeptierten und begonnenen Bewertungen.",
        // Todo: Matches/Matchmaking
        noRecentMatches: "Keine aktuellen Übereinstimmungen.",
        // Todo: Matches/Matchmaking
        noCaseEnquiries: "Aktuell keine Beauftragung.",
        recentMatchesWithCount: "Aktuelle Übereinstimmungen: {{count}}",
        caseEnquiries: "Beauftragungen: {{count}}",
        statistics: {
            cases: {
                active: "Aktiv",
                conversionRate: "Konversionsrate",
            },
            enquiries: {
                allOffersSent: "Vorgelegte Angebote",
                // Todo: Matches/Matchmaking
                matches: "Übereinstimmungen",
                rejectedOffers: "Abgelehnte Angebote",
                waitingFor: "Warten auf Entscheidung",
            },
        },
    },
    en: {
        activeCasesWithCount: "Active Cases: {{count}}",
        customizeExperience: {
            addTeamMembers: {
                title: "Add team members",
                subtext:
                    "Consider adding your team members to your company profile.",
            },
            completeProfile: {
                title: "Complete your profile",
                subtext:
                    "You have missing mandatory information about your company, please complete all relevant fields.",
            },
            customizeYourExperience: "Customize Your Experience",
            uploadMarketing: {
                title: "Upload marketing material",
                subtext:
                    "Uploading marketing material increases transparency among clients and can have positive impact on your offers acceptance.",
            },
            uploadPiCover: {
                title: "Upload PI cover",
                subtext:
                    "Uploading those documents increases transparency among clients and can have positive impact on your offers acceptance.",
            },
            uploadValuationSample: {
                title: "Upload valuation sample",
                subtext:
                    "Uploading valuation samples increases transparency among clients and can have positive impact on your offers acceptance.",
            },
        },
        noAcceptedStartedValuation: "No accepted and started valuations.",
        noRecentMatches: "No recent matches.",
        noCaseEnquiries: "No case enquiries.",
        recentMatchesWithCount: "Recent Matches: {{count}}",
        caseEnquiries: "Case Enquiries: {{count}}",
        statistics: {
            cases: {
                active: "Active",
                conversionRate: "Conversion Rate",
            },
            enquiries: {
                allOffersSent: "All Offers Sent",
                matches: "Matches",
                rejectedOffers: "Rejected Offers",
                waitingFor: "Waiting For Decision",
            },
        },
    },
};
