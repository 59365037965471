import { Heading, VStack } from "@chakra-ui/react";
import { EnquiryType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { EnquiryMatchInfo } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import { EnquiryRequirements } from "../../../../domain/enquiry/enquiryRequirements/EnquiryRequirements";
import { testOffers } from "../../../../domain/offer/testdata";
import { Section } from "../../../../layout";
import { CompanyOffers } from "./CompanyOffers";
import { Property } from "../../../../domain";

const translationNS = "screens.platform.valuer.valuerEnquiryDetails";

type Props = {
    enquiry: EnquiryType;
    matchInfo: EnquiryMatchInfo;
    device: "tablet" | "desktop" | undefined;
    myOffer: React.ReactNode;
};

const SinglePropertyLayout = (props: Props) => {
    const { enquiry, matchInfo, device, myOffer } = props;

    const t = useSmartTranslation();

    const property = enquiry.properties[0];

    const requestedValuation = (
        <Section
            title={t("domain.enquiry.requestedValuationTitle")}
            content={
                <EnquiryRequirements
                    enquiry={enquiry}
                    matchInfo={matchInfo}
                />
            }
        />
    );

    const map = (
        <Section
            content={
                <Property.PropertiesMap
                    properties={enquiry.properties}
                    selectedProperty={property}
                />
            }
        />
    );

    const propertyDetails = (
        <Section
            title={t('domain.property.propertyDetails.title')}
            content={
                <>
                    {device === "tablet" && (
                        <Property.PropertiesMap
                            properties={enquiry.properties}
                            selectedProperty={property}
                        />
                    )}

                    <Heading
                        size="md"
                        my=".5em"
                    >
                        {`${property.street}, ${property.postalCode} ${property.city}`}
                    </Heading>
                    <Property.PropertyDetails property={property} />
                </>
            }
        />
    );

    const offers = testOffers;

    const companyOffers = (
        <Section
            title={t(`${translationNS}.companyOffers.title`, {
                nCompanyOffers: 2,
            })}
            content={<CompanyOffers offers={offers} />}
        />
    );

    return (
        <>
            <VStack>
                {requestedValuation}
                {propertyDetails}
            </VStack>

            {device === "desktop" && <VStack>{map}</VStack>}

            <VStack>
                {companyOffers}
                {myOffer}
            </VStack>
        </>
    );
};

export default SinglePropertyLayout;
