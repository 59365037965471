import {
    Button,
    Flex,
    HStack,
    Spacer,
    Text,
    Textarea,
    VStack
} from "@chakra-ui/react";
import { FormikValues, useField, useFormikContext } from "formik";
import { useEffect } from "react";
import { Offer } from ".";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { Forms } from "../../common/forms/Forms";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import {
    OfferCreationFormData,
    OfferCreationFormDataType
} from "./OfferCreationFormOutput";

export type OfferEditorFormInitialValuesType = {
    price: string;
    piCoverValue: string;
    piCoverPercentage: string;
    timeline: string;
    availability: string;
    comments: string;
};

type Props = {
    initialValues: OfferEditorFormInitialValuesType;
    piCoverSpecialArrangementsAvailable?: boolean;
    onSubmit: (draft: OfferCreationFormDataType) => void;
    onCancel?: () => void;
};

export const Editor = (props: Props) => {
    const {
        onSubmit,
        onCancel,
        initialValues: baseValues,
        piCoverSpecialArrangementsAvailable,
    } = props;

    const t = useSmartTranslation();

    const cancelEditing = onCancel && (
        <Button
            w={"20%"}
            onClick={() => onCancel()}
        >
            {t("common.button.cancel")}
        </Button>
    );

    const submitButtonWidth = cancelEditing ? "80%" : "100%";
    const submitOffer = (
        <Button
            w={submitButtonWidth}
            variant={"primary"}
            type={"submit"}
        >
            {t("common.button.submit")}
        </Button>
    );

    const onFormSubmit = (values: FormikValues) => {
        const formOutput: OfferCreationFormDataType =
            OfferCreationFormData.cast(values);
        onSubmit(formOutput);
    };

    const initialValues = {
        ...baseValues,
        deliveryDate: "",
    };

    return (
        <Forms.FormikForm
            onSubmit={onFormSubmit}
            initialValues={initialValues}
            validationSchema={OfferCreationFormData}
        >
            <VStack gap={"1em"}>
                <HStack alignItems={"baseline"}>
                    <Forms.FormikInput
                        type={"number"}
                        name={"piCoverValue"}
                        readonly={!piCoverSpecialArrangementsAvailable}
                        label={t("domain.offer.piCoverAbsolute")}
                        leftinternal={"€"}
                    />
                    <Forms.FormikInput
                        type={"number"}
                        name={"piCoverPercentage"}
                        readonly={!piCoverSpecialArrangementsAvailable}
                        label={t("domain.offer.piCoverPercentage")}
                        rightinternal={"%"}
                    />
                    <Forms.FormikInput
                        type={"number"}
                        name={"price"}
                        label={t("domain.offer.price")}
                        leftinternal={"€"}
                        variant={"Price"}
                    />
                </HStack>
                <HStack alignItems={"baseline"}>
                    <Forms.FormikInput
                        name={"timeline"}
                        label={t("domain.offer.timeline")}
                        rightinternal={t("common.units.days")}
                    />
                    <Forms.FormikInput
                        type={"date"}
                        name={"availability"}
                        label={t("domain.offer.availability")}
                    />
                    <DeliveryDateField />
                </HStack>
                <OfferMessageEditor />
                <Flex
                    grow={1}
                    w={"100%"}
                    gap={".5rem"}
                >
                    {cancelEditing}
                    {submitOffer}
                </Flex>
            </VStack>
        </Forms.FormikForm>
    );
};

// following https://formik.org/docs/examples/dependent-fields
const DeliveryDateField = () => {
    const {
        values: { timeline, availability },
        touched,
        setFieldValue,
    } = useFormikContext<OfferCreationFormDataType>();

    const t = useSmartTranslation();

    useEffect(() => {
        let newValue = "";
        if (timeline !== "" && availability !== "") {
            const deliveryDate = Offer.calculateDeliveryDate({
                timeline,
                availability,
            });
            newValue = displayDateWithCurrentLocale(deliveryDate);
        }

        setFieldValue("deliveryDate", newValue);
    }, [timeline, availability, touched, setFieldValue]);

    return (
        <Forms.FormikInput
            readonly={true}
            name={"deliveryDate"}
            label={t("domain.offer.deliveryDate")}
        />
    );
};

const COMMENTS_MAX_LENGTH = 250;

const OfferMessageEditor = () => {
    const t = useSmartTranslation();

    const [field] = useField<"comments">("comments");

    const chars = field.value.length;
    const atCharLimit = chars === COMMENTS_MAX_LENGTH;
    const counterColor = atCharLimit ? "red.500" : undefined;
    const counter = (
        <Text color={counterColor}>
            {chars}/{COMMENTS_MAX_LENGTH}
        </Text>
    );

    return (
        <VStack
            w={"100%"}
            align={"start"}
        >
            <Flex w={"100%"}>
                <Text>{t("domain.offer.comments")}</Text>
                <Spacer />
                {counter}
            </Flex>
            <Textarea
                h={"8rem"}
                placeholder={t("domain.offer.commentsPlaceholder")}
                bg="white"
                borderRadius="10"
                maxLength={COMMENTS_MAX_LENGTH}
                {...field}
            />
        </VStack>
    );
};
