import { User } from "./User";

export const testUser = {
    firstName: "theFirstName",
    lastName: "theLastName",
    country: "theCountry",
    emailVerified: false,
} as User;

export const testValuerUser = {
    entityId: 2,
    id: 13,
    firstName: "Adam",
    lastName: "Valuer",
    orgPosition: "Valuer",
    entity: {
        name: "Valuer Company Ltd.",
    },
} as User;

export const testClientUser = {
    entityId: 1,
    id: 12,
    firstName: "Jane",
    lastName: "Client",
    orgPosition: "Owner",
    entity: {
        name: "Client Company",
    },
} as User;
