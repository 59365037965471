import { enquiryStatusTranslations } from "./enquiryStatus";

export const translations = {
    de: {
        ACTIVITIES: {
            archive: {
                NAME: "Archivieren",
                confirmationBlurb: "Möchten Sie diese Anfrage wirklich archivieren? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
            cancel: {
                NAME: "Abbrechen",
                confirmationBlurb: "Anfrage wirklich abbrechen?",
            },
        },
        createEnquiry: "Anfrage erstellen",
        daysLeft: "{{count}} Tage übrig",
        datePassed: "Datum überschritten",
        enquiries: "Anfragen",
        enquiryId: "Anfrage #{{id}}",
        purpose: "Zweck der Wertermittlung",
        statusLabel: "Status:",
        status: enquiryStatusTranslations.de,
        requestedValuationTitle: "Angeforderte Bewertung",
        valuationRequirements: {
            typeAndStandard: "Typ & Standard",
            expectedDelivery: "Gewünschtes Fertigstellungsdatum",
            specificScopes: "Spezielle Bewertungsumfänge",
            purpose: "Zweck",
            additionalRequirements: "Zusätzliche Anforderungen",
        },
        valuerRequirementsTitle: "Anforderungen an den Gutachter",
        valuerRequirements: {
            qualification: "Qualifikation",
            minExperience: "Mindesterfahrung des Gutachters (in Jahren)",
            minPartners: "Mindestanzahl von Partnern im Unternehmen",
            maxDistance: "Maximale Wegstrecke vom Arbeitsstandort zum Bewertungsobjekt",
            // Todo: PI-Cover
            minPiCover: "Haftpflicht Mindestdeckungssumme",
            noRequirement: "Keine Anforderung",
        },
        requirements: {
            experience: {
                "0": "Keine",
                "1-3 years": "1-3 Jahre",
                "3-5 years": "3-5 Jahre",
                "5+ years": "5+ Jahre",
            },
            partners: {
                "Single Partner": "Einzelunternehmer",
                "Two Partners": "Zwei Partner",
                "Three Partners": "Drei Partner",
                "Four or More Partners": "Vier oder mehr Partner",
            },
        },
    },
    en: {
        ACTIVITIES: {
            archive: {
                NAME: "Archive",
                confirmationBlurb: "Are you sure you want to archive this enquiry? This action cannot be undone.",
            },
            cancel: {
                NAME: "Cancel",
                confirmationBlurb: "Cancel enquiry?",
            },
        },
        createEnquiry: "Create enquiry",
        daysLeft: "{{count}} days left",
        datePassed: "Date passed",
        enquiries: "Enquiries",
        enquiryId: "Enquiry #{{id}}",
        purpose: "Purpose of Valuation",
        statusLabel: "Status:",
        status: enquiryStatusTranslations.en,
        requestedValuationTitle: "Requested Valuation",
        valuationRequirements: {
            typeAndStandard: "Type & Standard",
            expectedDelivery: "Expected Delivery",
            specificScopes: "Specific Scopes",
            purpose: "Purpose of Valuation",
            additionalRequirements: "Additional Requirements",
        },
        valuerRequirementsTitle: "Valuer Requirements",
        valuerRequirements: {
            qualification: "Qualification",
            minExperience: "Min. Post-Qualification",
            minPartners: "Min. Partners in Firm",
            maxDistance: "Max. Distance",
            minPiCover: "Min. PI Cover",
            noRequirement: "No requirement specified",
        },
        requirements: {
            experience: {
                "0": "0",
                "1-3 years": "1-3 Years",
                "3-5 years": "3-5 Years",
                "5+ years": "5+ Years",
            },
            partners: {
                "Single Partner": "Single Partner",
                "Two Partners": "Two Partners",
                "Three Partners": "Three Partners",
                "Four or More Partners": "Four or More Partners",
            },
        },
    },
};
