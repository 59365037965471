import { UserType } from "../../../../../../types";

type ValuerDetailsProps = {
    valuer: UserType;
};

const ValuerDetails = (props: ValuerDetailsProps) => {
    return <div>ValuerDetails</div>;
};

export { ValuerDetails };
