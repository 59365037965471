import { useDisclosure } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { EnquiryType } from "../../../../types";
import { SetDormantDto } from "../../../../utils/api/dto";
import { enquirySetDormant } from "../../../../utils/api/endpoints";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    enquiry: EnquiryType;
};

const useEnquirySetDormant = (params: ParamsType) => {
    const {
        enquiry: { id },
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { mutate: update } = useMutation(
        (dto: SetDormantDto) => enquirySetDormant(dto),
        {
            onSuccess: () => {
                onSuccess?.();
            },
            onError,
        },
    );

   const onConfirmDormant = () => {
        onClose();
        update({
            dormant: true,
            enquiryId: id,
        });
    };

    const confirmationModal = (
        <ConfirmationModal
            isOpen={isOpen}
            onConfirmation={onConfirmDormant}
            onClose={onClose}
            title={t("domain.enquiry.ACTIVITIES.archive.NAME")}
            blurb={t("domain.enquiry.ACTIVITIES.archive.confirmationBlurb")}
            confirmationButtonLabel={t("domain.enquiry.ACTIVITIES.archive.NAME")}
            confirmationButtonVariant={"neutral"}
        />
    );        

    return {
        update: onOpen,
        confirmationModal,
    };
};

export default useEnquirySetDormant;
