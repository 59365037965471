import { ascending } from "../../../../common/functions/sortNumbers";
import { StandardPropertyTypeWithMixed } from "../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import { PropertyType } from "../../../../types";

type PropertyTypeAndCount = {
    [key in StandardPropertyTypeWithMixed]: number;
};

export const createPropertyTypesCount = (
    properties: PropertyType[]
): PropertyTypeAndCount => {
    return properties
        .flatMap(({ propertyTypes }) => propertyTypes.length > 1 ? 'Mixed' : propertyTypes[0])
        .reduce((counts, type) => {
            if (type == undefined) return counts;

            if (!counts[type]) counts[type] = 1;
            else counts[type] += 1;

            return counts;
        }, {} as PropertyTypeAndCount);
};

export const calculateAveragePropertyArea = (
    properties: PropertyType[]
): number => {
    const totalArea = properties.reduce(
        (acc, property) => acc + property.areaGrossInternal,
        0
    );

    return totalArea / properties.length;
};

export const calcPropertyAreaMinMax = (properties: PropertyType[]) => {
    const areaList = properties
        .map((property) => property.areaGrossInternal)
        .sort(ascending);
    return {
        smallest: areaList[0],
        biggest: areaList[properties.length - 1],
    };
};

export const calcPropertyValueMinMax = (properties: PropertyType[]) => {
    const sortedPropertyValues = properties
        .map((property) => property.estimateValue)
        .sort(ascending);

    return {
        cheapest: sortedPropertyValues[0],
        mostExpensive: sortedPropertyValues[sortedPropertyValues.length - 1],
    };
};
