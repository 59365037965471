import { CaseType } from "../../../../types";
import { testEnquiry, testEnquiry2 } from "../enquiry/testdata";
import { testClientEntity, testValuerEntity } from "../entity/testdata";
import { acceptedOffer2 } from "../offer/testdata";
import { testClientUser, testValuerUser } from "../user/testdata";

export const testCase: CaseType = {
    id: 2234,

    status: "PENDING",

    createdAt: "2024-10-05",
    updatedAt: "2024-11-08",

    dormantForUserIds: [],

    acceptedOffer: acceptedOffer2,
    acceptedOfferId: acceptedOffer2.id,

    enquiry: testEnquiry2,
    enquiryId: testEnquiry2.id,

    clientEntity: testClientEntity,
    clientEntityId: testClientEntity.id,

    clientUser: testClientUser,
    clientUserId: testClientUser.id,

    valuerEntity: testValuerEntity,
    valuerEntityId: testValuerEntity.id,

    valuerUser: testValuerUser,
    valuerUserId: testValuerUser.id,

    inspections: [],
} as CaseType;

export const testCase2: CaseType = {
    id: 2235,

    status: "PENDING",

    createdAt: "2024-08-21",
    updatedAt: "2024-09-08",

    dormantForUserIds: [],

    acceptedOffer: acceptedOffer2,
    acceptedOfferId: acceptedOffer2.id,

    enquiry: testEnquiry,
    enquiryId: testEnquiry.id,

    clientEntity: testClientEntity,
    clientEntityId: testClientEntity.id,

    clientUser: testClientUser,
    clientUserId: testClientUser.id,

    valuerEntity: testValuerEntity,
    valuerEntityId: testValuerEntity.id,

    valuerUser: testValuerUser,
    valuerUserId: testValuerUser.id,

    inspections: [],
} as CaseType;

export const testCases: CaseType[] = [testCase, testCase2];
