import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { ArrowRight2 } from "iconsax-react";
import { ReactNode } from "react";
import { FiCheck } from "react-icons/fi";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { OfferOnlyType, OfferType } from "../../../../types";
import { TranslationFunction } from "../../common/hooks/useSmartTranslation";
import PublicUserProfilePictureWithEntityLogo from "../../common/components/PublicUserProfilePictureWithEntityLogo";
import { DataTableColumnType } from "../../common/components/data-table/DataTable";

type Column = DataTableColumnType<OfferOnlyType>;

const createRenderFunction = (dataName: keyof OfferOnlyType, columnDataName: (data: number | string | undefined) => ReactNode) => {
    return (offer: OfferOnlyType) => {
        const columnData = columnDataName(offer[dataName]);

        return (
            <VStack
                spacing="0"
            >
                <Text
                    textAlign="right"
                >
                    {columnData || "-"}
                </Text>
            </VStack>
        );
    };
};

export const valuerProfile: DataTableColumnType<OfferType> = {
    alignment: "left",
    title: (t) => t('common.components.dataTable.columns.offerColumns.valuer'),
    render: (o: OfferType) => {
        const offer = o;

        return (
            <HStack gap="16px">
                <PublicUserProfilePictureWithEntityLogo
                    entityId={offer.fromEntityId}
                    userId={offer.fromValuerId}
                />
                <VStack
                    alignItems="flex-start"
                    spacing="0"
                >
                    <Text fontWeight="bold">
                        {offer.fromValuer.firstName} {offer.fromValuer.lastName}
                    </Text>
                    <Text color="gray.500">
                        {offer.fromValuer.workingForCompanyName}
                    </Text>
                </VStack>
            </HStack>
        );
    },
};

export const piCoverValue: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t('common.components.dataTable.columns.offerColumns.piCoverValue'),
    render: createRenderFunction("piCoverValue", piCoverValue => piCoverValue &&`€${piCoverValue}`),
};

export const piCoverPercentage: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => "[%]",
    render: createRenderFunction("piCoverPercentage", piCoverPercentage => piCoverPercentage && `${piCoverPercentage}%`),
};

export const specialPi: DataTableColumnType<OfferType> = {
    alignment: "right",
    title: (t: TranslationFunction) => t('common.components.dataTable.columns.offerColumns.specialPi'),
    render: (offer: OfferType) => {
        const specialPiIcon = offer.fromEntity.piCoverSpecialArrangementsAvailable ?
            <FiCheck color="green" size="24px" /> : <CloseIcon boxSize="10px" color="gray.400" />;

        return (
            <Box>
                {specialPiIcon}
            </Box>
        );
    },
};

export const earliestAvailability: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t('common.components.dataTable.columns.offerColumns.earliestAvailability'),
    render: createRenderFunction("availability", availability => {
        if (!availability) return null;
        const availabilityDate = new Date(availability);
        return displayDateWithCurrentLocale(availabilityDate);
    }),
};

export const workingTime: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t('common.components.dataTable.columns.offerColumns.workingTime'),
    render: (o: OfferOnlyType, t: TranslationFunction) =>
        createRenderFunction("timeline", timeline => timeline && `${timeline} ${t('common.components.dataTable.columns.offerColumns.days')}`)(o),
};

export const delivery: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t('common.components.dataTable.columns.offerColumns.delivery'),
    render: createRenderFunction("availability", availability => {
        if (!availability) return null;
        const availabilityDate = new Date(availability);
        return displayDateWithCurrentLocale(availabilityDate);
    }),
};

export const price: Column = {
    alignment: "right",
    title: (t: TranslationFunction) => t('common.components.dataTable.columns.offerColumns.price'),
    render: createRenderFunction("price", price => price && <strong>{`€${price}`}</strong>)
};

export const viewCase: Column = {
    render: (offer: OfferOnlyType, t: TranslationFunction) => {
        return (
            <Button
                size="sm"
                as={ReactLink}
                to={`/dashboard/case/${offer.caseId}`}
                variant="primary"
                children={t(
                    "common.button.view"
                )}
            />
        );
    },
};

export const view: Column = {
    render: (offer: OfferOnlyType, t: TranslationFunction) => {
        return (
            <IconButton
                as={ReactLink}
                to={`/dashboard/enquiry/${offer.enquiryId}/offer/${offer.id}`}
                icon={<ArrowRight2 />}
                variant="ghost"
                color="gray.500"
                aria-label="arrow"
                data-testid="view-offer"
            />
        );
    },
};

export const offerColumns = {
    valuerProfile,
    piCoverValue,
    piCoverPercentage,
    specialPi,
    earliestAvailability,
    workingTime,
    delivery,
    price,
    viewCase,
    view,
};

