import { UserType } from "../../../../../../types";

type ValuerQualificationsProps = {
    valuer: UserType;
};

const ValuerQualifications = (props: ValuerQualificationsProps) => {
    return <div>ValuerQualifications</div>;
};

export { ValuerQualifications };
