import { CaseType, OfferOnlyType } from "../../../../../../types";

type OfferTitlePropsType = {
    offer: OfferOnlyType;
    case?: CaseType;
};

const OfferTitle = (props: OfferTitlePropsType) => {
    return <div>OfferTitle</div>;
};

export { OfferTitle };
