import { StandardPropertyTypeWithMixed } from "../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";

type TranslationNSObject = {
    [key in StandardPropertyTypeWithMixed]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const propertyTypeTranslations: Translations = {
    de: {
        Agricultural: "Landwirtschaft",
        Commercial: "Gewerblich",
        Development: "Bauvorhaben",
        Land: "Grundstück",
        Mixed: 'Gemischt',
        Residential: "Wohnwirtschaftlich",
        Special: "Besonderheiten",
    },
    en: {
        Agricultural: "Agricultural",
        Commercial: "Commercial",
        Development: "Development",
        Land: "Land",
        Mixed: 'Mixed',
        Residential: "Residential",
        Special: "Special",
    },
};
