import { UserType } from "../../../../../../types";

type ValuerCompetencesProps = {
    valuer: UserType;
};

const ValuerCompetences = (props: ValuerCompetencesProps) => {
    return <div>ValuerCompetences</div>;
};

export { ValuerCompetences };
