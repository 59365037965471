export const caseTranslations = {
    de: {
        cases: "Geschäftsfälle",
        daysLeft: "{{count}} Tage übrig",
        datePassed: "Datum überschritten",
    },
    en: {
        cases: "Cases",
        daysLeft: "{{count}} days left",
        datePassed: "Date passed",
    },
};
